'use strict';

angular.module('project')
.factory('projectService', ['tsSettings', '$resource', '$rootScope', '$state', '$q', '$http', function(tsSettings, $resource, $rootScope, $state, $q, $http) {
    "ngInject";

    var schema = [
        'id',
        'tag',
        'name',
        'description',
        'users',
        'json_data'
    ];

    var json_data_schema = [
        'facebook_url',
        'ios_app_store_url',
        'android_app_store_url'
    ];

    // utility function to create a clean, deep copy of projects
    // TODO: client side validation
    function initProject(prj) {
        var result = {};

        if (prj === undefined) {
            return result;
        }

        prj.json_data = updateJsonDataFieldFromProject(prj);

        for (s in schema) {
            result[schema[s]] = prj[schema[s]];
        }

        result = updateProjectFromJsonDataField(result);

        return result;
    }

    function updateJsonDataFieldFromProject(prj) {
        var json_data = "{"
        for (k in json_data_schema)
        {
            k = json_data_schema[k];

            if (prj[k] !== undefined)
            {
                // if not the first loop iteration add 
                // a comma.
                if (json_data !== "{") {
                    json_data = json_data + ", ";
                }

                json_data = json_data + "\"" + k + "\": \"" + prj[k] + "\"";
            }
        }
        if (json_data === "{")
            return null
        else
            return json_data + "}";
    }

    function updateProjectFromJsonDataField(prj)
    {
        if (!prj.json_data) {
            return prj;
        }
        
        var json_data = JSON.parse(prj.json_data);
        for (var k in json_data_schema)
        {
            k = json_data_schema[k];
            if (json_data[k] !== undefined) {
                prj[k] = json_data[k];
            }
            else {
                prj[k] = "";
            }

        }
        return prj;
    }

    var _user = {};
    var _initialised = false;

    var firebaseService = {

        // simple list of {id, tag, name} for all projects
        list: [],

        // global, currently selected project
        current: {
            project: {}
        },

        currentValue: null,

        init: function(user, forceInit) {
            var deferred = $q.defer();

            if (_initialised && !forceInit) {
                return $q.when();
            }

            firebaseService.list = [];
            
            var projectsRef = firebase.database().ref('projectsByUID/'+user.uid);

            projectsRef.once('value', function(result) {
                console.log(result.val());
                //var projectsByTag = result.val();
                var promises = [];
                var projects = result.val();
                for (var tag in projects)
                {
                    var promise = firebase.database().ref('projectMetadata/'+tag).once('value');
                    promises.push(promise);                    
                }

                $q.all(promises).then(function(results) {
                    for (var idx in results)
                    {
                        var projectMetaData = results[idx].val();
                        var schemas = [];
                        for (var key in projectMetaData.schemas.content)
                            schemas.push({data:projectMetaData.schemas.content[key]});

                        var designSchemas = [];
                        for (var key in projectMetaData.schemas.design)
                            designSchemas.push({data:projectMetaData.schemas.design[key]});

                        projectMetaData.designSchemas = designSchemas;
                        projectMetaData.schemas = schemas;

                        firebaseService.list.push(projectMetaData);                      
                    }

                    if (firebaseService.list.length > 0) {
                        firebaseService.setCurrent(firebaseService.list[0]).then(function() {
                            _initialised = true;
                            deferred.resolve();
                        });
                    }
                })
                .catch(function (msg) {
                    console.log(msg);
                });
            })
           .catch(function (error) {
                console.error("Error getting project list");
                console.error(error);
                deferred.reject();
            });

            return deferred.promise;
        },

        setCurrentUser: function(user) {
            _user = user;
        },

        userValidator: function(user) {
            // utility to validate users via bsl
            // returns a promise that resolves to a user record
            return $resource(tsSettings.adminUrl('user/'), {}, {
                    get: {
                        method: 'GET',
                        transformResponse: 
                            function (data) {
                                return JSON.parse(data).objects;
                            },
                        isArray: true
                    }
                })
                .get({
                    email: user,
                }).$promise;
        },

        // update this.current, broadcast update to all listeners
        setCurrent: function(project) {

            var deferred = $q.defer();


            var projectDataRef = firebase.database().ref('projectData/'+project.tag+'/admin/project');
            projectDataRef.once('value', function(result) {
                    if (result.val()) {
                        // set the current project
                        firebaseService.current.project = project;
                        firebaseService.currentValue = firebaseService.current.project;
                        // set it's fb page if has one        
                        firebaseService.currentValue.facebook_url = result.val().facebookURL || null;
                        firebaseService.currentValue.linkedAccounts = result.val().linkedAccounts || null;

                        deferred.resolve();
                    }
            });

            return deferred.promise;
        },

        createNew: function() {
            this.current.project = initProject();
            if (this.current.project.users === undefined) {
                this.current.project.users = [];
            }

            var current = this.current;
            $resource(tsSettings.adminUrl('user/'))
            .get().$promise
            .then(function (result) {
                current.project.users.push(result.objects[0]);
            })
            .catch(function (error) {
                console.error('Error validating current user.');
            });

            return this.current.project;
        },

        editCurrent: function() {
            // return the promise so the user can either listen for event or wait on the
            // promise
            var current = this.current;

            return $resource(tsSettings.adminUrl('project/:id/'), {id: '@id'}).get({id: this.current.project.id}).$promise
                .then(function (result) {

                    current.project = result;

                    current.project = updateProjectFromJsonDataField(current.project);

                    return current.project;
                });
        },

        getLinkedAccounts: function() {
            return firebaseService.currentValue.linkedAccounts;
        },

        disconnectDropbox: function() {
            var projectDataRef = firebase.database().ref('projectData/'+this.current.project.tag+'/admin/project/linkedAccounts/dropbox');
            projectDataRef.set({});
        },

        saveCurrent: function() {
            var project = initProject(this.current.project);

            // replace each user record with corresponding user URI
            var users = project.users;
            project.users = [];
            for (var u in users) {
                project.users.push(users[u].resource_uri);
            }

            var resource;
            var newProject = false;
            if (project.id === undefined) {

                // new project since it has no id field
                newProject = true;
                resource = $resource(tsSettings.adminUrl('project/'));

            } else {

                resource = $resource(tsSettings.adminUrl('project/:id/'),
                    {id: '@id'},
                    {
                        'save': { method:'PATCH' }
                    });
            }

            var list = this.list;
            var current = this.current;
            return resource.save(project).$promise
                .then(function(result) {
                    if (newProject) {
                        // add the newly created event to the list
                        list.push(result);
                    } else {
                        var found = false;
                        // replace the event in the list
                        for (p in list) {
                            if (list[p].id == result.id) {

                                list[p] = result;
                                found = true;
                                break;
                            }
                        }
                        if (!found) {
                            console.error("failed to update ")
                        }
                        // TODO: handle missing existing project
                    }

                    current.project = result;
                    return current.project;
                });
        },
    };

    var service = {

        // simple list of {id, tag, name} for all projects
        list: [],

        // global, currently selected project
        current: {
            project: {}
        },

        currentValue: null,

        init: function() {
            var deferred = $q.defer();

            // initialise intiial list and current project
            $resource(tsSettings.adminUrl('project/')).get().$promise
            .then(function (result) {
                // need to copy project items to the existing list object
                // since controllers are referencing the list
                service.list.length = 0;
                for (var o in result.objects) {
                    service.list.push(result.objects[o]);
                }

                if (service.list.length > 0) {

                    // set the current project
                    service.current.project = service.list[0];
                    service.current.project = updateProjectFromJsonDataField(service.current.project);

                    // initialise the schema for this project
                    $http.get(tsSettings.jsonUrl('/' + service.current.project.tag + '/schemas/'))
                    .then(function(results) {
                        // do a deep copy rather than replacing schemas object
                        // since its referred to by controllers
                        service.current.project.schemas = [];
                        for (var v in results.data.values) {
                            service.current.project.schemas.push(results.data.values[v]);
                        }

                        // now get this projects project value
                        $http.get(tsSettings.jsonUrl('/' + service.current.project.tag + '/project/'))
                        .then(function(results) {  
                            service.currentValue = results.data.data;
                            deferred.resolve();
                        });                      
                    });

                } else {
                    // TODO: we have no project. Need to create a project
                    deferred.resolve();
                }

            })
            .catch(function (error) {
                console.error("Error getting project list");
                console.error(error);
                deferred.reject();
            });

            return deferred.promise;

        },

        userValidator: function(user) {
            // utility to validate users via bsl
            // returns a promise that resolves to a user record
            return $resource(tsSettings.adminUrl('user/'), {}, {
                    get: {
                        method: 'GET',
                        transformResponse: 
                            function (data) {
                                return JSON.parse(data).objects;
                            },
                        isArray: true
                    }
                })
                .get({
                    email: user,
                }).$promise;
        },

        // update this.current, broadcast update to all listeners
        setCurrent: function(id) {
            for (p in this.list) {
                if (this.list[p].id == id) {
                    this.current.project = this.list[p];

                    // change global state to the new project
                    $state.go('.', {project: this.current.project.tag});

                    return this.current;
                }
            }

            error = "Invalid project id: " + id;
            console.error(error);
            throw Error(error); 
        },

        createNew: function() {
            this.current.project = initProject();
            if (this.current.project.users === undefined) {
                this.current.project.users = [];
            }

            var current = this.current;
            $resource(tsSettings.adminUrl('user/'))
            .get().$promise
            .then(function (result) {
                current.project.users.push(result.objects[0]);
            })
            .catch(function (error) {
                console.error('Error validating current user.');
            });

            return this.current.project;
        },

        editCurrent: function() {
            // return the promise so the user can either listen for event or wait on the
            // promise
            var current = this.current;

            return $resource(tsSettings.adminUrl('project/:id/'), {id: '@id'}).get({id: this.current.project.id}).$promise
                .then(function (result) {

                    current.project = result;

                    current.project = updateProjectFromJsonDataField(current.project);

                    return current.project;
                });
        },

        saveCurrent: function() {
            var project = initProject(this.current.project);

            // replace each user record with corresponding user URI
            var users = project.users;
            project.users = [];
            for (var u in users) {
                project.users.push(users[u].resource_uri);
            }

            var resource;
            var newProject = false;
            if (project.id === undefined) {

                // new project since it has no id field
                newProject = true;
                resource = $resource(tsSettings.adminUrl('project/'));

            } else {

                resource = $resource(tsSettings.adminUrl('project/:id/'),
                    {id: '@id'},
                    {
                        'save': { method:'PATCH' }
                    });
            }

            var list = this.list;
            var current = this.current;
            return resource.save(project).$promise
                .then(function(result) {
                    if (newProject) {
                        // add the newly created event to the list
                        list.push(result);
                    } else {
                        var found = false;
                        // replace the event in the list
                        for (p in list) {
                            if (list[p].id == result.id) {

                                list[p] = result;
                                found = true;
                                break;
                            }
                        }
                        if (!found) {
                            console.error("failed to update ")
                        }
                        // TODO: handle missing existing project
                    }

                    current.project = result;
                    return current.project;
                });
        },
    };

    /*if (localStorage.getItem('backend') === 'bsl')
    {
        return service;
    }
    else if (localStorage.getItem('backend') === 'firebase')
    {*/
    return firebaseService;
    //}

}]);
