'use strict';

angular.module('design')
.factory('designStorageService', ['tsSettings', '$stateParams', '$http', '$rootScope', '$state', '$q', function(tsSettings, $stateParams, $http, $rootScope, $state, $q) {

    function getType(data) {
        // data:image/png;base64,<the encoded image>
        return data.split(',')[0].split(';')[0].split(':')[1];
    }

    function getExtension(data) {
          // data:image/png;base64,<the encoded image>
          //return data.split(',')[0].split(';')[0].split(':')[1];
          //return 'jpg';
          var extension;
          var lowerCase = data.toLowerCase();
          if (lowerCase.indexOf("png") !== -1) extension = "png"
          else if (lowerCase.indexOf("jpg") !== -1 || lowerCase.indexOf("jpeg") !== -1)
              extension = "jpg"
          else extension = "tiff";
          return extension;
    }

    function uploadImages(images,baseURI)
    {

        var storageRef = firebase.storage().ref();

        var uploadTaskPromises = [];
        for (var idx in images) {

            var upload = (function() {

            var metadata = {};
            var name = images[idx].name;

            // Upload file and metadata to the object 'images/mountains.jpg'
            var uploadTask = storageRef.child(baseURI + "/" + name + ".jpg").put(images[idx].data, metadata);

            // Listen for state changes, errors, and completion of the upload.
            uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
              function(snapshot) {
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log('Upload is ' + progress + '% done');
                switch (snapshot.state) {
                  case firebase.storage.TaskState.PAUSED: // or 'paused'
                    console.log('Upload is paused');
                    break;
                  case firebase.storage.TaskState.RUNNING: // or 'running'
                    console.log('Upload is running');
                    break;
                }
              }, function(error) {
              switch (error.code) {
                case 'storage/unauthorized':
                  // User doesn't have permission to access the object
                  break;

                case 'storage/canceled':
                  // User canceled the upload
                  break;

                case 'storage/unknown':
                  // Unknown error occurred, inspect error.serverResponse
                  break;
              }
            }, function() {
              // Upload completed successfully, now we can get the download URL
                uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                      var val = {}
                      val[name] = downloadURL;
                      images[idx].value = downloadURL;
                      return firebase.database().ref(baseURI).update(val);
                });
            });
            uploadTaskPromises.push(uploadTask);
            });
            upload();
        }
        return uploadTaskPromises;
    }

    var firebaseService = {
        schemas: [],
        
        getValue: function(uri) {
            var deferred = $q.defer();
            var valueRef = firebase.database().ref(uri);
            valueRef.once('value', function(result) {
                var value = result.val();
                deferred.resolve({data:value||{},uri:uri,resource:null});
            });
            return deferred.promise;            
        },

        getValues: function(uri) {
            var deferred = $q.defer();
            var valueRef = firebase.database().ref(uri);
            valueRef.once('value', function(result) {
                var values = [];
                result.forEach(function(childSnapshot) {
                    var childKey = childSnapshot.key;
                    var childData = childSnapshot.val();
                    values.push({data:childData,uri:uri+childKey});
                });
                 deferred.resolve({'values':values});
            });
            return deferred.promise;
        },
        saveValue: function(value, endpoint, schema) {

            // Upload any updated images in the value
            var images = [];
            for (var el in schema) {
                if (schema[el].type === 'bsl-image' && schema[el].formControl.$dirty) {
                    // Get the image name and base64 value
                    var key = schema[el].key.replace("data.","");
                    var base64Data = value.data[key];

                    if (value.data[key]) {
                        // Create Blob to upload
                        var binary = atob(base64Data.split(',')[1]);
                        var array = [];
                        for(var i = 0; i < binary.length; i++) {
                            array.push(binary.charCodeAt(i));
                        }
                        var file = new Blob([new Uint8Array(array)]);  

                        // Create the image object for upload later. 
                        var image = {name:key,data:file};
                        images.push(image);

                        // Delete the key in the value, we don't want to upload
                        // the base64 value of the image
                        delete value.data[key];
                    }      
                }
                // convert all date values to unix timestamp for firebase storage
                else if (schema[el].type === 'bsl-datetime')
                {
                    var key = schema[el].key.replace("data.","");
                    if ('key' in value.data) {
                        value.data[key] = value.data[key].getTime();
                    }
                }
            }

            var resource = undefined;
            if (value.resource) {
                resource = value.resource;
                delete value.resource;
            }

            // clone value as we're modifying the date
            var val = JSON.parse(JSON.stringify(value.data)) ;

            // now return the date to a date object
            for (var el in schema) {
                if (schema[el].type === 'bsl-datetime')
                {
                    var key = schema[el].key.replace("data.","");
                    value.data[key] = new Date(value.data[key]);
                }
            }

            if (endpoint) {

                // create new
                val._created = firebase.database.ServerValue.TIMESTAMP;
                var key = firebase.database().ref(endpoint).push().key;
                value.key = key;
                return firebase.database().ref(endpoint+"/"+key).set(val).then(function() {

                        var uploadTaskPromises = uploadImages(images, endpoint+"/"+key);
                        return $q.all(uploadTaskPromises);
                    }
                );
            } else {
                // update existing
                val._modified = firebase.database.ServerValue.TIMESTAMP;
                return firebase.database().ref(value.uri).set(val).then(function() {

                        var uploadTaskPromises = uploadImages(images, value.uri);
                        return $q.all(uploadTaskPromises);
                    }
                );
            }
        },
        deleteValue: function(value, schema) {

            for (var el in schema) {
                if (schema[el].type === 'bsl-image') {

                    // Get the image name and base64 value
                    var key = schema[el].key.replace("data.","");

                    // Delete the resource
                    var storageRef = firebase.storage().ref();
                    // Create a reference to the file to delete
                    var desertRef = storageRef.child(value.uri + "/" + key + ".jpg");

                    // Delete the file
                    desertRef.delete().then(function() {
                      // File deleted successfully
                      console.log('deleted');
                    }).catch(function(error) {
                      // Uh-oh, an error occurred!
                      console.log(error);
                    });  

                }
            }
            
            if (value.data.resource)
            {
                // delete the resource
                var storageRef = firebase.storage().ref();
                 // Create a reference to the file to delete
                var desertRef = storageRef.child(value.uri + ".jpg");

                // Delete the file
                desertRef.delete().then(function() {
                  // File deleted successfully
                  console.log('deleted');
                }).catch(function(error) {
                  // Uh-oh, an error occurred!
                  console.log(error);
                });  
            }
                     
            return firebase.database().ref(value.uri).remove();
        },

    }

    var service = {
        schemas: [],

        // get values tupple {values, prev, next} for a given endpoint
        getValue: function(uri) {
            return $http.get(tsSettings.jsonUrl(uri))
            .then(function(result) {
                return result.data;
            })
            .catch(function(result) {
                return $q.reject(result.statusText);
            });
        },

        // for firebase backend, we need to implement lists and single
        // values diff so just put a dummy function here
        getValues: function(uri) {
            return service.getValue(uri);
        },

        updateValue: function(value) {
            return $http.put(tsSettings.jsonUrl(value.uri), value)
            .then(function(result) {
                return result.data;
            })
            .catch(function(result) {
                return $q.reject(result.statusText);
            });
        },

        deleteValue: function(value) {
            return $http.delete(tsSettings.jsonUrl(value.uri))
            .then(function(result) {
                return result.data;
            })
            .catch(function(result) {
                return $q.reject(result.statusText);
            });
        },

        saveValue: function(value, endpoint) {
            var promise = undefined;
            var resource = undefined;

            if (value.resource) {
                resource = value.resource;
                delete value.resource;
            }

            if (endpoint) {
                promise = $http.post(tsSettings.jsonUrl(endpoint), value);
            } else {
                promise = $http.put(tsSettings.jsonUrl(value.uri), value)
            }

            promise = promise
            .then(function (result) {
                return result.data;
            })

            if (!resource) {
                return promise;
            }

            // get a pre-signed URL to upload the resource to s3

            return promise
            .then(function (result) {

                return $http.get(tsSettings.jsonUrl(result.uri + '?resource=' + getExtension(resource)));
            })
            .then(function (result) {
                // do upload to S3
                var binary = atob(resource.split(',')[1]);
                var array = [];
                for(var i = 0; i < binary.length; i++) {
                    array.push(binary.charCodeAt(i));
                }
                var blob = new Blob([new Uint8Array(array)]);

                return $http.put(result.data.auth, blob, {
                    transformRequest: angular.identity,
                    headers: {'Content-Type': undefined, Authorization: undefined},
                })
                .then(function () {
                    return {
                        uri      : result.data.uri,
                        resource : result.data.url,
                    };
                });
            })
            .then(function(result) {
                // update the value with the final url
                return $http.put(tsSettings.jsonUrl(result.uri), result);
            })
            .then(function(result) {
                // finally, just return the new value
                return result.data;
            })
            .catch(function(result) {
                return $q.reject(result.statusText);
            });
        },

    };

    /*if (localStorage.getItem('backend') === 'bsl')
    {
        return service;
    }
    else if (localStorage.getItem('backend') === 'firebase')
    {*/
    return firebaseService;
    //}


}]);
