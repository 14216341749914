'use strict';

angular.module('content')
.factory('audienceService', ['$http', '$rootScope', '$q', function($http, $rootScope, $q) {

    var _groups = {};
    var _groupListUrl = "projectData/bluizeBarAndGrill/admin/memberGroups/";  
    var _audienceListUrl = "projectData/bluizeBarAndGrill/fromBluize/member/";
    var _cachedAudienceGroups = {'Everyone':{criteria:"true",display:"Everyone (pre-defined group)",name:"Everyone"}};

    function initialise(groupListUrl,audienceListUrl) {
        
        var deferred = $q.defer();

        _groupListUrl = groupListUrl || _groupListUrl;
        _audienceListUrl = audienceListUrl || _audienceListUrl;

        var groupsRef = firebase.database().ref(_groupListUrl);
        groupsRef.once('value', function(result) {
            var snap = result.val();
            for (var key in snap) {
                _groups[snap[key].name] = snap[key];
                _cachedAudienceGroups[snap[key].name] = [];
            }
            _cachedAudienceGroups['Everyone'] = []
            deferred.resolve();
        });

        return deferred.promise;

    }

    function addGroup(name,criteria) {
        var groupsRef = firebase.database().ref(_groupListUrl);
        return groupsRef.push({name:name,criteria:criteria});
    }

    function getGroups() {
        return _groups;
    }

    function isMemberInGroup(group,member)
    {
        var bool = eval(group.criteria);
        return bool;
    }

    function getAudienceGroup(groups, useCached) 
    {
        var deferred = $q.defer();
        var audienceGroup = [];
  
        if (useCached) {
            for (var group in groups) {
                if (group.name in _cachedAudienceGroups) {
                    audienceGroup.concat(_cachedAudienceGroups[group.name]);
                }
                deferred.resolve(audienceGroup);
            }
        }
        else {
            var membersRef = firebase.database().ref(_audienceListUrl);
            membersRef.once('value', function(result) {
                var snap = result.val();
                for (var key in snap) {
                    for (var idx in groups) {
                        var group = groups[idx];
                        if (isMemberInGroup(group,snap[key])) {
                            audienceGroup.push(snap[key]);
                            _cachedAudienceGroups[group.name].push(snap[key]);
                        }
                    }
                }
                deferred.resolve(audienceGroup);
            });
        }

        return deferred.promise;
    }

    var service = {

        initialise: initialise,

        getAudienceGroup: getAudienceGroup,

        getGroups: getGroups

    };

    return service;


}]);
