'use strict';

angular.module('formlyFieldTypes')
.run(function(formlyConfig) {
    "ngInject";
    
    formlyConfig.setType({
      name: 'bsl-autocomplete',
      templateUrl: 'formlyFieldTypes/bsl-autocomplete/template.html',
      controller: function($scope, $q) {
        $scope.selectedChips = [];
        $scope.selectedItem = null;
        $scope.searchText = null;
        $scope.getAutocompleteItems = function() {
            var deferred = $q.defer();
            var valueRef = firebase.database().ref($scope.to.autoCompleteUri);
            valueRef.once('value', function(result) {
                var values = [];
                result.forEach(function(childSnapshot) {
                    var childKey = childSnapshot.key;
                    var childData = childSnapshot.val();
                    childData['display'] = childData[$scope.to.displayKey];
                    childData['id'] = childKey;
                    console.log(childData);
                    values.push(childData);
                });
                 deferred.resolve(values);
            });
            return deferred.promise;
        }
        $scope.autocompleteSelectedItemChange = function(item) {
          console.log($scope.selectedItem);
        }
      $scope.transformChip = function(chip) {
        // If it is an object, it's already a known chip
        if (angular.isObject(chip)) {
          return chip;
        }
        // Otherwise, create a new one
        return { name: chip, type: 'new' }
      }
      }
    });

});
