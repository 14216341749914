'use strict';

angular.module('auth')
.factory('authService', function() {
    "ngInject";

    var _config = null;

    var api = {
        setConfig: function(config){
        	_config = config;
        }
    };
    
    return api;
});