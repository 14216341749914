'use strict';

function convertToDataURLviaCanvas(url, callback, outputFormat){
    var img = new Image();
    img.crossOrigin = 'Anonymous';
    img.onload = function(){
        var canvas = document.createElement('CANVAS');
        var ctx = canvas.getContext('2d');
        var dataURL;
        canvas.height = this.height;
        canvas.width = this.width;
        ctx.drawImage(this, 0, 0);
        dataURL = canvas.toDataURL(outputFormat);
        callback(dataURL);
        canvas = null; 
    };
    img.src = url;
}


angular.module('design')
.controller('designTabCtrl',
function($scope, projectService, designTabsService) {
    "ngInject";

    $scope.project = projectService.current.project;
    $scope.schemas = projectService.current.project.designSchemas;

    $scope.schemaName = function(schema) {
        return schema.data.name || schema.data.valueUri;
    };

    $scope.selectSchema = designTabsService.selectSchema; 

})

angular.module('design')
.controller('designCtrl',
function (designStorageService, designTabsService, $state, $scope, $q, $mdDialog, projectService, facebookService, $rootScope, $window) {
    "ngInject";

    $scope.activeSchema = undefined;
    $scope.detailsForm = {};

    // Bind values from servies to $scope
    Object.defineProperty($scope, 'activeIndex', {
        get: function() { 
            return designTabsService.activeIndex; 
        },
        set: function(val) { designTabsService.activeIndex = val; },
    });

    function setDetailsFormPristine() {
        if ($scope.detailsForm.form)
            $scope.detailsForm.form.$setPristine();
        for (var el in $scope.activeSchema.data.schema) {
            if ($scope.activeSchema.data.schema[el].formControl) {
                if ($scope.activeSchema.data.schema[el].formControl.$setPristine)
                    $scope.activeSchema.data.schema[el].formControl.$setPristine();
            }
        }
    }

    $scope.$watch('activeIndex', function(newValue) {
        if (typeof newValue === "undefined" || newValue < 0 || newValue == $scope.schemas.length) {
            return;
        }

        $scope.activeSchema = $scope.schemas[newValue];
        
        if ($scope.activeSchema.data.displayType==="detail")
        {
            designStorageService.getValue($scope.activeSchema.data.valueUri)
            .then(function(result) {
                $scope.schemaValue = result;
                setDetailsFormPristine();
            });
        }
        else
        {
            designStorageService.getValues($scope.activeSchema.data.valueUri)
            .then(function(result) {
                $scope.schemaValue = result;
                setDetailsFormPristine();
            });
        }

        refreshValue();
    });

    $scope.schemas = projectService.current.project.designSchemas;

    $scope.schemaValue = {};

    function refreshValue() {
        if ($scope.activeSchema.data.displayType==="detail")
        {
            designStorageService.getValue($scope.activeSchema.data.valueUri)
            .then(function(result) {
                $scope.schemaValue = result;
                setDetailsFormPristine();

            });            
        }
        else
        {
            designStorageService.getValues($scope.activeSchema.data.valueUri)
            .then(function(result) {
                $scope.schemaValue = result;
                setDetailsFormPristine();
            });
        }
    }

    $scope.schemaName = function(schema) {
            return schema.data.name || schema.data.valueUri;
    }


    // for a given value, return the summary for display in a list/title
    $scope.valueSummary = function(value) {
        var summary = undefined;

        if ($scope.activeSchema.data.summary && value.data) {
            summary = value.data[$scope.activeSchema.data.summary];
        }

        return summary ? summary : "---";
    }

    $scope.editValue = function(uri) {
        // uri == undefined implies creation of new value
        $state.go('design.detail', {schemaIndex: $scope.activeIndex, uri: uri, initData:null});
    }

    $scope.editValueFromFb = function(fbEvent) {
        var d = new Date(fbEvent.event_times[0].time);
        var hour = ("0" + d.getHours()).slice(-2);
        var min = ("0" + d.getMinutes()).slice(-2);

        var initData = {name:fbEvent.short_name, 
                        description:fbEvent.description, 
                        resource:fbEvent.cover_url,
                        date:d,
                        time: hour + ":" + min
                        };
        $state.go('design.detail', {schemaIndex: 1, uri: undefined, initData:initData});
    }

    $scope.deleteValue = function(value) {
        designStorageService.deleteValue(value,$scope.activeSchema.data.schema)
         .then(function() {
             //wizardAppService.removeAppShow(value.uri);
             refreshValue();
         });
    }

    $scope.confirmDelete = function(value) {

        var confirm = $mdDialog.confirm()
            .title('Confirm Delete')
            .textContent('Are you sure you\'d like to delete this item?')
            .ariaLabel('Secondary click demo')
            .ok('Delete')
            .cancel('Cancel');

        $mdDialog.show(confirm).then(function() {
            $scope.deleteValue(value);
        }, function () {});

    };
 
     facebookService.initFacebookSDK().then(function() {
            facebookService.initFacebookEventData(projectService.currentValue.facebook_url).then(function() {
                $scope.fb_events = facebookService.fb_events();
            });
        });   
    
    $scope.busy = false;

    $scope.save = function() {
        // remove angular properties if any (e.g. $$hashkey)
        $scope.schemaValue = angular.copy($scope.schemaValue);
        
        $scope.busy = true;
        var promise = designStorageService.saveValue($scope.schemaValue, null, $scope.activeSchema.data.schema);

        promise.then(function(result) {
            $scope.errorMsg = "None" 
            $scope.busy = false;
            refreshValue();
            $rootScope.$digest();
            $scope.$digest();
            //$window.location.reload();
            $scope.$apply();
        })
        .catch(function(result) {
            $scope.errorMsg = "Server error: " + result;
            $scope.busy = false;
        })
    }

});


angular.module('design')
.controller('designDetailCtrl',
function (designStorageService, $state, $stateParams, $scope, projectService) {
    "ngInject";

    // error message on save/create
    $scope.errorMsg = undefined;
    $scope.busy = false;

    $scope.schema = projectService.current.project.designSchemas[$stateParams.schemaIndex].data;
    $scope.initData = $stateParams.initData;

    // Initialise schema value
    $scope.schemaValue = {};

    if ($stateParams.initData)
    {
        $scope.schemaValue = {data: $stateParams.initData, 
                             'initFromUrl': $stateParams.initData.resource};
    }

    if (!$stateParams.uri) {
        // we're creating a new value
        $scope.action = "create";
    } else {
        designStorageService.getValue($stateParams.uri)
        .then(function(result) {
            // split into date and time field
            if (result.data.date) {
                result.data.date = new Date(result.data.date)
                result.data.time = result.data.date.getHours() + ":" + result.data.date.getMinutes();
            }
            $scope.schemaValue = result;
        });
        $scope.action = "save"
    }

    // for a given value, return the summary for display in a list/title
    $scope.title = function() {
        if ($scope.action == "create") {
            var name = $scope.schema.name || "item";

            return name;
        } else {
            var name = undefined;

            if ($scope.schema.summary && $scope.schemaValue.data) {
                name = $scope.schemaValue.data[$scope.schema.summary];
            }

            name = name || "item";
            return name;
        }
    }

    $scope.save = function() {
        $scope.busy = true;

        // remove angular properties if any (e.g. $$hashkey)
        $scope.schemaValue = angular.copy($scope.schemaValue);

        // add the time to the date object
        /*if ($scope.schemaValue.data.time && $scope.schemaValue.data.date)
        {
            var hours = parseInt($scope.schemaValue.data.time.split(':')[0], 10);
            var min = parseInt($scope.schemaValue.data.time.split(':')[1], 10);

            $scope.schemaValue.data.date.setHours(hours);
            $scope.schemaValue.data.date.setMinutes(min);
        }*/

        if ($stateParams.uri) {
            var resourceDirty = false;
            var schema = $scope.schema.schema;
            for (var el in schema) {
                if (schema[el].key == 'resource' && schema[el].formControl.$dirty) {
                    resourceDirty = true;
                }
            }
            
            if ($scope.schemaValue.resource && !resourceDirty) {
                // don't update the resource if it hasn't changed
                delete $scope.schemaValue.resource;
            }

            var promise = designStorageService.saveValue($scope.schemaValue, null, schema);
        } else {
            var promise = designStorageService.saveValue($scope.schemaValue, $scope.schema.valueUri, $scope.schema.schema);
        }

        promise
        .then(function(result) {
            if ($scope.schema.name === "Session") {
                /*wizardAppService.addAppShow(result.uri, 
                                        result.data.name, 
                                        result.data.description, 
                                        result.resource, Date.now());*/
            }
            $scope.busy = false;
            $state.go('design.list');    
        })
        .catch(function(result) {
            $scope.errorMsg = "Server error: " + result;
            $scope.busy = false;
        });
    }

    $scope.cancel = function() {
        $state.go('design.list');        
    }

});

