'use strict';

angular.module('project')
.controller('projectController',
['bsl_project', 'bsl_auth', '$scope', function (bsl_project, bsl_auth, $scope) {

    // configure formly to display editors for the following fields
    $scope.projectEditFields = [

            {
              key: 'name',
              type: 'bsl-text',
              templateOptions: {
                label: 'Project Name',
                placeholder: 'name',
                help: 'Project name for display, including spaces etc.'
              },
            },
            /* USEFUL WHEN DEBUGGING JSON FIELD STUFF gs
            {
              key: 'json_data',
              type: 'bsl-text',
              templateOptions: {
                label: 'JSON Data',
                placeholder: 'json_data',
                help: 'User defined JSON data for this project.'
              },
            },
            */
            {
              key: 'facebook_url',
              type: 'bsl-text',
              templateOptions: {
                label: 'Facebook URL',
                placeholder: 'facebook_url',
                help: 'Facebook Page URL for this project.'
              },
            },    
            {
              key: 'ios_app_store_url',
              type: 'bsl-text',
              templateOptions: {
                label: 'App iTunes Store URL',
                placeholder: 'ios_app_store_url',
                help: 'Link to download the iOS app.'
              },
            },        
            {
              key: 'android_app_store_url',
              type: 'bsl-text',
              templateOptions: {
                label: 'App Google Play Store URL',
                placeholder: 'android_app_store_url',
                help: 'Link to download the Android app.'
              },
            },      
            {
              key: 'description',
              type: 'bsl-textarea',
              templateOptions: {
                label: 'Project Description',
                placeholder: 'description',
                help: 'Description of project.'
              },
            },
            {
              key: 'users',
              type: 'bsl-email-list',
              templateOptions: {
                label: 'Project Administrators',
                field: 'email',
                placeholder: 'a_user@test.com',
                validator: bsl_project.userValidator,
                help: 'Email address of all users enabeld to modify this project.'
              },
            },
        ];

    // can specify tag on creation
    $scope.projectCreateFields = [{
              key: 'tag',
              type: 'bsl-text',
              templateOptions: {
                label: 'Project Tag',
                placeholder: 'tag',
                help: 'Letters and underscores.'
              },
            }].concat($scope.projectEditFields);


    $scope.current = bsl_project.current;

    // save button handler -> save results to BSL
    $scope.save = function () {
        bsl_project.saveCurrent();
    };

    $scope.ShowLink = undefined;
    function updateLinkStatus() {
        bsl_auth.is_linked()
        .then(function(result) {
            if (result) {
                $scope.ShowLink = false;
            } else {
                $scope.ShowLink = true;
            }
        })
    }
    updateLinkStatus();

    $scope.FBLink = function () {

        FB.init({
            appId      : '679866138753998',
            status     : true,
            xfbml      : true,
            version    : 'v2.3' // or v2.0, v2.1, v2.0
        });

        FB.getLoginStatus(function(response) {
            var accessToken = undefined;

            if (response.status === 'connected') {
                // the user is logged in and has authenticated your
                // app, and response.authResponse supplies
                // the user's ID, a valid access token, a signed
                // request, and the time the access token 
                // and signed request each expire
                accessToken = response.authResponse.accessToken;

            } else if (response.status === 'not_authorized') {
                // the user is logged in to Facebook, 
                // but has not authenticated your app
                console.log('User didnt authorize app.');
            } else {
                FB.login(function(response) {
                    if (response.authResponse) {

                        accessToken = response.authResponse.accessToken;

                    } else {
                        console.log('User cancelled login or did not fully authorize.');
                    }
                });
            }

            if (accessToken !== undefined) {
                bsl_auth.fb_token(accessToken)
                .then(function(result) {
                    updateLinkStatus();
                })
                .catch(function(result) {
                    console.error("Unknown server error linking FB account");
                })
            }

        });

    }


}]);



