'use strict';

angular.module('main')
.config(function($stateProvider) {
    'ngInject';

    $stateProvider
    .state({
        name: 'main',
        url: '/main',
        controller: 'mainCtrl',
        templateUrl: 'main/main.html',
    })
    // .state('main.body', {
    //     views: {
    //         'content': {
    //             controller: 'contentCtrl',
    //             templateUrl: 'content/content.html'
    //         }
    //     }
    // })
    // .state('main.detail', {
    //     params: {schemaIndex: null, uri: null, initData: null},
    //     url: "/detail",
    //     views: {
    //         'content': {
    //             controller: 'contentDetailCtrl',
    //             templateUrl: 'content/contentDetail.html'
    //         }
    //     }
    // })
    // .state('main.facebook', {
    //     views: {
    //         'content': {
    //             controller: 'facebookCtrl',
    //             templateUrl: 'facebook/facebook.html'
    //         }
    //     }
    // })    
});
