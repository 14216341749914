'use strict';

angular.module('facebook')
.controller('facebookCtrl', function ($scope, facebookService, projectService, contentStorageService) {
    'ngInject';

    facebookService.initFacebookSDK().then(function() {
        facebookService.initFacebookEventData(projectService.currentValue.facebook_url).then(function() {
            $scope.fb_events = facebookService.fb_events();
        });
    });
});
