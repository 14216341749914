
angular.module('common',
    [
    	// common vendor modules
		'ui.router',
		'ngResource',
        'ngMaterial',

        // other modules within common/
        'md-theme'
    ]);

var globalConfig = {
    apiUrl: 'https://backstagelive.co/',
};

angular.module('common')
.value('tsSettings', (function() {
  return {
        
        authUrl: function (url) {
            return globalConfig.apiUrl + 'api_v2/rest-auth/' + url;
        },

        jsonUrl: function(url) {
            return globalConfig.apiUrl + 'api_v2' + url;
        },

        adminUrl: function (url) {
            return globalConfig.apiUrl + 'admin/api/v1/' + url;
        },

        bslProjectUrl: function () {
            return globalConfig.apiUrl + 'api/v1/project/';
        },

        adminApiUrl: function (project, url) {
            return globalConfig.apiUrl + project + '/admin/api/v1/' + url;
        },

        sroProxyUrl: function (project) {
            return globalConfig.apiUrl + project + '/admin/proxy/sro/';
        },
  };
})());

angular.module('common')
.config(function($resourceProvider, $locationProvider) {
    'ngInject';

    // // Add hashbang prefix for SEO and HTML5 mode to remove #! from the URL.
    // // Html5 mode requires server-side configuration. See http://bit.ly/1qLuJ0v
    // $locationProvider.html5Mode(true).hashPrefix('!');

    // For any unmatched url, redirect to /
    //$urlRouterProvider.otherwise('/wizard');


  $resourceProvider.defaults.stripTrailingSlashes = false;
})

angular.module('common')
.factory('dashboardConfigService', function() {
    "ngInject";

    var _config = null;

    var api = {
        getConfig: function() {
            return _config;
        },

        setConfig: function(config){
            _config = config;
        }
    };
    
    return api;
});
