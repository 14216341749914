'use strict';

// copied from here http://dl.dropboxusercontent.com/u/513327/xmlToJSON.html
function xmlToJson(xml) {
    var attr,
        child,
        attrs = xml.attributes,
        children = xml.childNodes,
        key = xml.nodeType,
        obj = {},
        i = -1;

    if (key == 1 && attrs.length) {
      obj[key = '@attributes'] = {};
      while (attr = attrs.item(++i)) {
        obj[key][attr.nodeName] = attr.nodeValue;
      }
      i = -1;
    } else if (key == 3) {
      obj = xml.nodeValue;
    }
    while (child = children.item(++i)) {
      key = child.nodeName;
      if (obj.hasOwnProperty(key)) {
        if (obj.toString.call(obj[key]) != '[object Array]') {
          obj[key] = [obj[key]];
        }
        obj[key].push(xmlToJson(child));
      }
      else {
        obj[key] = xmlToJson(child);
      }
    }
    return obj;
}

angular.module('SRO').factory('SROService', ['$http', '$window', '$q', function ($http, $window, $q) {

    var events = [];
    var fb_events = [];
    var id = 0;

    var initData = function(project) {

        var deferred = $q.defer();

        // clear old event data
        events.length = 0;

        // Add 'sro' proxy to bsl via admin/ interface
        // http://purchase.yourcentre.com.au/feed/events/?groupby=show&withpricing=true
        var xml_url = 'https://purchase.yourcentre.com.au/feed/events/?groupby=show&withpricing=true&withfutureevents=true';
        var authValue = $http.defaults.headers.common.Authorization;
        delete $http.defaults.headers.common.Authorization;

        $http.get(xml_url).then(function(response) 
        {
            var xml = response.data
            if (window.DOMParser) {
                xml = (new DOMParser).parseFromString(xml,'text/xml');
            } else if (window.ActiveXObject) {
                xml = [new ActiveXObject('Microsoft.XMLDOM'), xml];
                xml[0].async = false;
                xml[0].loadXML(xml[1]);
                xml = xml[0];
            }

            json = xmlToJson(xml);
            console.log(json);
            
            for (show_idx in json.feed.Show)
            {
                var event_times = [];
                var anEvent = {};
                var show = json.feed.Show[show_idx];
                anEvent.short_name = show.Name['#text'];
                anEvent.external_url = show.DirectLink['#text'];
                var pricing_matrix = {};

                if (show.events.Event instanceof Array)
                {
                    for (event_time_idx in show.events.Event)
                    {
                        var event_time = {};
                        value = show.events.Event
                        event_time.time = show.events.Event[event_time_idx].EventDate['#text'];
                        event_times.push(event_time);

                        // get pricing details
                        pricing_matrix = get_pricing_from_event(show.events.Event[event_time_idx])
                        var venue = show.events.Event[event_time_idx].VenueName['#text'];
                    }
                }
                else
                {
                    var event_time = {};
                    event_time.time = show.events.Event.EventDate['#text'];
                    event_times.push(event_time);
                    pricing_matrix = get_pricing_from_event(show.events.Event)
                    var venue = show.events.Event.VenueName['#text'];
                }

                anEvent.event_times = event_times;
                anEvent.pricing = JSON.stringify(pricing_matrix.STANDARD);
                anEvent.venue = venue;
                anEvent.id = id;
                id++;
                events.push(anEvent);
            }
            deferred.resolve();
          });
        
        return deferred.promise;
    };

return {
    initData: initData,

    all: function () {
        return events;
    },

    get: function (eventId) {
        return events[eventId];
    },
    
    get_from_name: function(name) {
        for (idx in events)
        {
            if (events[idx].short_name === name)
                return events[idx];
        }
        return null;
    }
};

}])

