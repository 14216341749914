'use strict';

angular.module('common')
.controller('optoutCtrl', function ($scope, $stateParams, $mdDialog, $http) {
    'ngInject';

    $scope.project = $stateParams.project;
    console.log($scope.project);

    $scope.optout = function(mobile)
    {
        if ($scope.project && mobile)
        {

            var data = {'project':$scope.project, 'type': "MOBILE", 'data':JSON.stringify({"mobile":mobile})};
            var endpointUrl = "https://i56lt1htx1.execute-api.ap-southeast-2.amazonaws.com/dev/optout";
            console.log(data);

            $http.post(endpointUrl, data).then(function(data) {
                var alert = $mdDialog.alert({
                    title: 'Unsubscribe',
                    textContent: 'You have been unsubscribed from the sms list.',
                    ok: 'OK'
                });
                $mdDialog
                    .show( alert )
                    .finally(function() {
                        alert = undefined;
                    });
            },
            function(error) {
                console.log(error);
                var alert = $mdDialog.alert({
                    title: 'Error',
                    textContent: 'We were unable to unsubscribe you at this time. Please try again later.',
                    ok: 'OK'
                });
                $mdDialog
                    .show( alert )
                    .finally(function() {
                        alert = undefined;
                    });            });
        }
    };

});
