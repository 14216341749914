'use strict';

// simple service to share state between tab bar and tab content
angular.module('content')
.factory('contentTabsService', function() {
    "ngInject";

    var result = {
        activeIndex: 0,
    };

    result.selectSchema = function(index) {
            result.activeIndex = index;            
    };
    
    return result;
});