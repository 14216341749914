'use strict';

angular.module('dashboard',
    [
    	// our own dependencies
		'dashboardPartials',   
        'common',
        'content',
        'design',
        'formlyFieldTypes',
        'imageAdmin',
        'fileUpload',
        'main',
        'project',
        'facebook',
        'SRO',
        'auth',
        // 3rd party dependencies
        'formly',
        'formlyMaterial',
        'ngMaterial',
        'mp.colorPicker',
        'ckeditor',             // html editor
        'ui.tinymce'
    ]);

angular.module('dashboard')
.run(function($http, $state, projectService, $location, dashboardConfigService) {
    'ngInject';

    console.log('printing location');
    console.log($location.path());
    console.log(window.location);

    var key = localStorage.getItem('auth');
    if (key) {
        $http.defaults.headers.common.Authorization = 'Token ' + key;
    }


    if (window.location.pathname === '/goact/')
    {
      // Initialize Firebase
      var config = {
        apiKey: "AIzaSyDOmFOFcVduxyJ57x8LvOv_ZSC6B6WgK08",
        authDomain: "bluize-loyalty.firebaseapp.com",
        databaseURL: "https://bluize-loyalty.firebaseio.com",
        storageBucket: "bluize-loyalty.appspot.com",
        messagingSenderId: "683341905600"
      };
      firebase.initializeApp(config);
      // Initialise Logo
      var dashboardConfig = {
        navBarLogo: "http://goact.co/wp-content/themes/goact/images/logo-goact-white.png",
        //appTemplates: ['http://flinders-cardio-app.s3-website-ap-southeast-2.amazonaws.com']
        appTemplates: ['http://goact-webcontainer.backstagelive.co/index.html']
      }
      dashboardConfigService.setConfig(dashboardConfig);
    }
    else if (window.location.pathname === '/bluize/' || window.location.host === "d3b50z8e8tbkpz.cloudfront.net" || window.location.host === "yourvenueyourapp.com.au")
    {
      // Initialize Firebase
      var config = {
        apiKey: "AIzaSyDOmFOFcVduxyJ57x8LvOv_ZSC6B6WgK08",
        authDomain: "bluize-loyalty.firebaseapp.com",
        databaseURL: "https://bluize-loyalty.firebaseio.com",
        storageBucket: "bluize-loyalty.appspot.com",
        messagingSenderId: "683341905600"
      };
      firebase.initializeApp(config);
      // Initialise Logo
      var dashboardConfig = {
        navBarLogo: "../img/BluizeWebLogo_300.png",
        appTemplates: ["https://d2zzd7o6kk1gek.cloudfront.net/"],
        stripePublicKey: 'pk_live_Piev8xPWixYSydLv8ZG4PfUM',
        stripeBillingEndpoint: 'https://19l24h8r1d.execute-api.ap-southeast-2.amazonaws.com/dev/billing'
      }
      dashboardConfigService.setConfig(dashboardConfig);
    }
    else if (window.location.pathname === '/pocketsoftware/')
    {
      // Initialize Firebase
      var config = {
        apiKey: "AIzaSyDOmFOFcVduxyJ57x8LvOv_ZSC6B6WgK08",
        authDomain: "bluize-loyalty.firebaseapp.com",
        databaseURL: "https://bluize-loyalty.firebaseio.com",
        storageBucket: "bluize-loyalty.appspot.com",
        messagingSenderId: "683341905600"
      };
      firebase.initializeApp(config);
      // Initialise Logo
      var dashboardConfig = {
        navBarLogo: "http://www.pocketsoftware.com.au.s3-website-ap-southeast-2.amazonaws.com/images/pocket_software_logo.png",
      }
      dashboardConfigService.setConfig(dashboardConfig);
    }
    else if (window.location.pathname === '/turnstyle/')
    {
      // Initialize Firebase
      var config = {
        apiKey: "AIzaSyDOmFOFcVduxyJ57x8LvOv_ZSC6B6WgK08",
        authDomain: "bluize-loyalty.firebaseapp.com",
        databaseURL: "https://bluize-loyalty.firebaseio.com",
        storageBucket: "bluize-loyalty.appspot.com",
        messagingSenderId: "683341905600"
      };
      firebase.initializeApp(config);
      // Initialise Logo
      var dashboardConfig = {
        navBarLogo: "http://www.turnstyle.it/landing/images/logo-wide-rev.svg",
        navBarStyle: "{'background-color': '#000000'}",
        mainCSS: "html,body,md-content{background-color:white !important}",
        signInCSS: "html,body,md-content{background-color:gray !important} \
        #input_0,#input_1{border-bottom-color:#B8B8B8 } \
        body > md-content > div > div.layout-align-center-center.layout-column > md-input-container:nth-child(2) > label {color:#B8B8B8 } \
        body > md-content > div > div.layout-align-center-center.layout-column > md-input-container:nth-child(3) > label {color:#B8B8B8 }\
        "
      }
      dashboardConfigService.setConfig(dashboardConfig);
    }
    else if (window.location.pathname === '/promengin/' || window.location.host === 'd1c6st74iy0pt4.cloudfront.net')
    {
        // Initialize Firebase
        var config = {
          apiKey: "AIzaSyDOmFOFcVduxyJ57x8LvOv_ZSC6B6WgK08",
          authDomain: "bluize-loyalty.firebaseapp.com",
          databaseURL: "https://bluize-loyalty.firebaseio.com",
          storageBucket: "bluize-loyalty.appspot.com",
          messagingSenderId: "683341905600"
        };
        firebase.initializeApp(config);
        // Initialise Logo
        var dashboardConfig = {
          navBarLogo: "https://s3-us-west-2.amazonaws.com/secure.promengin.com/promengin.png",
          navBarStyle: "{'background-color': '#000000'}",
          mainCSS: "html,body,md-content{background-color:white !important}",
          signInCSS: "html,body,md-content{background-color:black !important} \
          #input_0,#input_1{border-bottom-color:#B8B8B8 } \
          body > md-content > div > div.layout-align-center-center.layout-column > md-input-container:nth-child(2) > label {color:#B8B8B8 } \
          body > md-content > div > div.layout-align-center-center.layout-column > md-input-container:nth-child(3) > label {color:#B8B8B8 }\
          "
        }
        dashboardConfigService.setConfig(dashboardConfig);
    }
    else if (window.location.pathname === '/demo/' || window.location.host === 'dashboard.turnstyle.it')
    {
      // Initialize Firebase
      var config = {
        apiKey: "AIzaSyDOmFOFcVduxyJ57x8LvOv_ZSC6B6WgK08",
        authDomain: "bluize-loyalty.firebaseapp.com",
        databaseURL: "https://bluize-loyalty.firebaseio.com",
        storageBucket: "bluize-loyalty.appspot.com",
        messagingSenderId: "683341905600"
      };
      firebase.initializeApp(config);
      // Initialise Logo
      var dashboardConfig = {
        appTemplates: ["https://d2zzd7o6kk1gek.cloudfront.net/"],
        navBarLogo: "http://www.turnstyle.it/landing/images/logo-wide-rev.svg",
        navBarStyle: "{'background-color': '#000000'}",
        mainCSS: "html,body,md-content{background-color:white !important}",
        signInCSS: "html,body,md-content{background-color:gray !important} \
        #input_0,#input_1{border-bottom-color:#B8B8B8 } \
        body > md-content > div > div.layout-align-center-center.layout-column > md-input-container:nth-child(2) > label {color:#B8B8B8 } \
        body > md-content > div > div.layout-align-center-center.layout-column > md-input-container:nth-child(3) > label {color:#B8B8B8 }\
        "
      }
      dashboardConfigService.setConfig(dashboardConfig);
    }
    else if (window.location.pathname === '/zenglobal/' || window.location.host === 'd1sktbwnydqpq4.cloudfront.net')
    {
      // Initialize Firebase
      var config = {
        apiKey: "AIzaSyDOmFOFcVduxyJ57x8LvOv_ZSC6B6WgK08",
        authDomain: "bluize-loyalty.firebaseapp.com",
        databaseURL: "https://bluize-loyalty.firebaseio.com",
        storageBucket: "bluize-loyalty.appspot.com",
        messagingSenderId: "683341905600"
      };
      firebase.initializeApp(config);
      // Initialise Logo
      var dashboardConfig = {
        appTemplates: ["https://d2zzd7o6kk1gek.cloudfront.net/"],
        navBarLogo: "http://fibre.zenglobal.net/web/images/ZenGlobal.png",
        navBarStyle: "{'color': 'gray', 'background-color':'white'}",
        mainCSS: "html,body,md-content{background-color:white !important}",
        signInCSS: "html,body,md-content{background-color:black !important} \
        #input_0,#input_1{border-bottom-color:#B8B8B8 } \
        body > md-content > div > div.layout-align-center-center.layout-column > md-input-container:nth-child(2) > label {color:#B8B8B8 } \
        body > md-content > div > div.layout-align-center-center.layout-column > md-input-container:nth-child(3) > label {color:#B8B8B8 }\
        #select_value_label_0 {color:gray !important} \
        "
      }
      dashboardConfigService.setConfig(dashboardConfig);
    }
    else if (window.location.pathname === '/qantum/' || window.location.host === "d1gi3880p3ktko.cloudfront.net")
    {
      // Initialize Firebase
      var firebaseConfig = {
        apiKey: "AIzaSyAtEqBUdg2yTdmIBwj0RZOQVs2kHPoop6U",
        authDomain: "qantum-loyalty.firebaseapp.com",
        databaseURL: "https://qantum-loyalty.firebaseio.com",
        projectId: "qantum-loyalty",
        storageBucket: "qantum-loyalty.appspot.com",
        messagingSenderId: "926879942990",
        appId: "1:926879942990:web:aa03514f204902b6aeb61f"
      };
      // Initialize Firebase
      firebase.initializeApp(firebaseConfig);
      // Initialise Logo
      var dashboardConfig = {
        navBarLogo: "https://placehold.co/150x50/white/white",
        navBarStyle: "{'color': 'gray', 'background-color':'white'}",
        mainCSS: "html,body,md-content{background-color:white !important}",
        signInCSS: "html,body,md-content{background-color:black !important} \
        #input_0,#input_1{border-bottom-color:#B8B8B8 } \
        body > md-content > div > div.layout-align-center-center.layout-column > md-input-container:nth-child(2) > label {color:#B8B8B8 } \
        body > md-content > div > div.layout-align-center-center.layout-column > md-input-container:nth-child(3) > label {color:#B8B8B8 }\
        #select_value_label_0 {color:gray !important} \
        ",
        //appTemplates: ['https://d6hmwor6asch0.cloudfront.net/',"https://d14rnvzdd4oqsx.cloudfront.net/"]
      }
      dashboardConfigService.setConfig(dashboardConfig);
    }

    console.log($location.path());

    if ($location.path().indexOf("/unsubscribe") >= 0)
    {
      return;
    }
    else if ($location.path().indexOf("/optout") >= 0)
    {
      return;
    }
    else
    {
      var dontInit = false;
      console.log(firebase);
      firebase.auth().onAuthStateChanged(function(user) {
        if (user) {

              if (dontInit)
                return;
              else
                dontInit = true;

              projectService.init(user,true).then(function (result) {
                  var state = localStorage.getItem('promenginRedirectState');
                  localStorage.removeItem('promenginRedirectState');
                  if (state) 
                  {
                    state = JSON.parse(state);
                    if (state.name === 'connectDropboxRequested') {
                      var access_token = parseQueryString($location.path());
                      console.log("access_token");
                      console.log(access_token);                  
                      if (access_token) {
                        console.log(access_token);
                        var endpoint = "projectData/" + state.stateParams.project + "/admin/project/linkedAccounts/dropbox";
                        var ref = firebase.database().ref(endpoint);
                        ref.set({'accessToken':access_token}).then(
                          function(result) {
                            $state.go('dropboxLink', {message:"You have now linked your Dropbox account."});
                          },
                          function() {
                            $state.go('dropboxLink', {success:"There was an error trying to link your Dropbox account."});
                          }
                        );
                      }
                    }
                  }
                  else {
                      $state.go('content.list');
                  }
              },
              function(error) {
                  $state.go('signIn');
              });

        } else {
          $state.go('signIn');
        }
      });
    }
    
    function parseQueryString(str)
    {

      if (typeof str !== 'string') {
        return null;
      }

      str = str.trim().replace(/^(\?|#|&)/, '');

      if (!str) {
        return null;
      }

      var params = str.split('&');
      for (var idx in params){
        var param = params[idx];
        var parts = param.replace(/\+/g, ' ').split('=');
        if (parts[0]==="/access_token") {
          return parts[1];
        }
      }

      return null;
    }

});

var tmpAngular = angular;

angular.element(document).ready(function() {
//    setTimeout(function() {
  tmpAngular.bootstrap(document, ['dashboard']);

});

