'use strict';

angular.module('formlyFieldTypes')
.run(function(formlyConfig) {
    "ngInject";

    formlyConfig.setType({
      	name: 'bsl-texteditor',
      	templateUrl: 'formlyFieldTypes/bsl-texteditor/template.html',
      	controller: function($scope) {
		    $scope.tinymceOptions = {
        		toolbar: 'undo | bold italic formatselect bullist numlist | link image | alignleft aligncenter alignright | mybutton | forecolor backcolor | code',
		        menubar: 'edit',
		        statusbar: false,
		        setup: function(editor) {
		        	if ('bluize' in $scope.to) {
	        		    var dbValues = [
					      {text: "Account Available Balance", value: "{{member.AccountAvailableBalance}}"},
					      {text:"Account Type", value: "{{member.AccountType}}"},
					      {text:"Card Number", value: "{{member.CardNumber}}"},
					      {text:"Current Status Points", value: "{{member.CurrentStatusPoints}}"},
					      {text:"Date Joined", value: "{{member.DateJoined}}"},
					      {text:"Email", value: "{{member.Email}}"},
					      {text:"First Name", value: "{{member.FirstName}}"},
					      {text:"Gender", value: "{{member.Gender}}"},
					      {text:"Id", value: "{{member.Id}}"},
					      {text:"Last Name", value: "{{member.LastName}}"},
					      {text:"Membership Type", value: "{{member.MembershipType}}"},
					      {text:"Mobile", value: "{{member.Mobile}}"},
					      {text:"Points Balance", value: "{{member.PointsBalance}}"},
					      {text:"Points Value", value: "{{member.PointsValue}}"},
					      {text:"Post Code", value: "{{member.PostCode}}"},
					      {text:"Required Status Points For Next Tier", value: "{{member.RequiredStatusPointsForNextTier}}"}
					    ];
			            editor.addButton('mybutton', {
			                  type: 'listbox',
			                  text: 'Insert Field ...',
			                  icon: false,
			                  onselect: function (e) {
			                    editor.insertContent(this.value());
			                  },
			                  values: dbValues,
			                  onPostRender: function () {
			                    // Select the second item by default
			                    //this.value('&nbsp;<em>Some italic text!</em>');
			                  }
			                });
			        }
		        },        
		        onChange: function(e) {
		          // put logic here for keypress and cut/paste changes
		        },
		        inline: false,
		        skin: 'lightgray',
		        baseURL: '/dashboard/tinymce',
		        plugins : 'paste advlist autolink link image lists code charmap print preview textcolor colorpicker autoresize',
		        theme : 'modern'
		    };
		}
    });
    
});