// Implements bsl-image-admin which is where the magic happens
angular.module('fileUpload').directive('fileUpload', function() {
    "ngInject";

    return {
        templateUrl: 'fileUpload/fileUpload.html',
        link: function link(scope, element, attrs) {
            console.log(attrs); 

            // find the model name
            var modelName = attrs.ngModel.replace("model.data.", "");
            var prefix = "uploads/" + scope.$parent.$parent.$parent.$parent.projectTag + "/";
            var childName = "", downloadUrl = "";

            // check if there is already a file associated with it
            if ('data' in scope.$parent.$parent.$parent.$parent.schemaValue) {
                if (modelName in scope.$parent.$parent.$parent.$parent.schemaValue.data) {
                  scope.childName = scope.$parent.$parent.$parent.$parent.schemaValue.data[modelName]['name'] || "";
                  scope.downloadUrl = scope.$parent.$parent.$parent.$parent.schemaValue.data[modelName]['url'] || "";
                }
            }

            scope.$parent.$parent.$parent.$parent.$watch('schemaValue', function() {
                if ('data' in scope.$parent.$parent.$parent.$parent.schemaValue) {
                  if (modelName in scope.$parent.$parent.$parent.$parent.schemaValue.data) {
                    scope.childName = scope.$parent.$parent.$parent.$parent.schemaValue.data[modelName]['name'] || "";
                    scope.downloadUrl = scope.$parent.$parent.$parent.$parent.schemaValue.data[modelName]['url'] || "";                
                  }
                }
            });

            scope.delete = function() {
                var storageRef = firebase.storage().ref().child(prefix + scope.childName);
                console.log("deleting:"+prefix + scope.childName);
                // Delete the file
                storageRef.delete().then(function() {
                  // File deleted successfully
                  console.log('file deleted');
                  scope.childName = "";
                  delete scope.$parent.$parent.$parent.$parent.schemaValue.data[modelName]['name'];
                  delete scope.$parent.$parent.$parent.$parent.schemaValue.data[modelName]['url'];
                  scope.$parent.$parent.$parent.$parent.detailsForm.$dirty = true;

                  scope.progress = 0;
                  scope.$parent.$apply();

                }).catch(function(error) {
                  // Uh-oh, an error occurred!
                  console.log('Uh-oh, an error occurred!');
                  console.log(error);
                });
            }

            scope.beginUpload = function($event, dirName) {
                var btn = $event.currentTarget;
                btn.disabled = true;
                if (!dirName)
                    dirName = ""; 
                console.log(scope.files);
                var storageRef = firebase.storage().ref();
                childName = prefix + dirName + "/" + scope.files[0].name;
                var mountainImagesRef = storageRef.child(childName);
                console.log("uploding file:"+childName);
                var uploadTask = mountainImagesRef.put(scope.files[0]);
                scope.progress = 1;

                // Register three observers:
                // 1. 'state_changed' observer, called any time the state changes
                // 2. Error observer, called on failure
                // 3. Completion observer, called on successful completion
                uploadTask.on('state_changed', function(snapshot){
                  // Observe state change events such as progress, pause, and resume
                  // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                  scope.progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                  console.log('Upload is ' + scope.progress + '% done');
                  scope.$apply();
                    

                  switch (snapshot.state) {
                    case firebase.storage.TaskState.PAUSED: // or 'paused'
                      console.log('Upload is paused');
                      break;
                    case firebase.storage.TaskState.RUNNING: // or 'running'
                      console.log('Upload is running');
                      break;
                  }
                }, function(error) {
                  // Handle unsuccessful uploads
                }, function() {
                    // Handle successful uploads on complete
                    // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                    btn.disabled = false;
                    mountainImagesRef.getDownloadURL().then(function(downloadUrl) {
                      console.log("File available at", downloadUrl);
                      //var downloadUrl = uploadTask.snapshot.downloadURL;
                      console.log('upload finished');
                      console.log(downloadUrl);
                      scope.downloadUrl = downloadUrl;
                      console.log(uploadTask.snapshot);
                      scope.childName = dirName + "/" + scope.files[0].name;
                      scope.$parent.$parent.$parent.$parent.schemaValue.data[modelName] = {'name':scope.childName, 'url':scope.downloadUrl};
                      scope.$parent.$parent.$parent.$parent.detailsForm.$dirty = true;
                      scope.$parent.$apply();
                    });

                    /*var storageRef = firebase.storage().ref().child(scope.childName);
                    // Delete the file
                    storageRef.delete().then(function() {
                      // File deleted successfully
                      console.log('file deleted');
                    }).catch(function(error) {
                      // Uh-oh, an error occurred!
                      console.log('Uh-oh, an error occurred!');
                    });*/
                });

            }
        }
    };

});

angular.module('fileUpload').directive('fdInput', [function () {
    return {
        link: function (scope, element, attrs) {
            element.on('change', function  (evt) {
                //var files = evt.target.files;
                //console.log(files[0].name);
                //console.log(files[0].size);
                //console.log(ngModel);
                //console.log();
                scope.files = evt.target.files;
                scope.$parent.$apply();
            });
        }
    }
}]);