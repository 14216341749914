'use strict';

angular.module('formlyFieldTypes')
.run(function(formlyConfig) {
    "ngInject";
    
    formlyConfig.setType({
		name: 'bsl-time',
		templateUrl: 'formlyFieldTypes/bsl-time/template.html',
		controller: ['$scope', function($scope) {
		    console.log($scope);
		}]
    });

});
