'use strict';

angular.module('common')
.config(function($stateProvider) {
    'ngInject';

    $stateProvider
    .state({
        name: 'unsubscribe',
        url: '/unsubscribe/:project',
        controller: 'unsubscribeCtrl',
        templateUrl: 'unsubscribe.html',
    })
    .state({
        name: 'optout',
        url: '/optout/:project',
        controller: 'optoutCtrl',
        templateUrl: 'optout.html',
    })
    .state({
        name: 'dropboxLink',
        url: '/dropboxLink',
        templateUrl: 'dropboxLink.html',
        params: {message: null},
        controller: function($scope, $stateParams, $window) {
            console.log("dropboxLink");
            console.log($stateParams);
        	$scope.message = $stateParams.message;
            $scope.close = function(){
                $window.close();
            }
        }
    })
});
