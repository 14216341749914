'use strict';

// simple service to share state between tab bar and tab content
angular.module('design')
.factory('designTabsService', function() {
    "ngInject";

    var result = {
        activeIndex: -1,
    };

    result.selectSchema = function(index) {
            result.activeIndex = index;            
    };
    
    return result;
});