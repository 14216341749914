'use strict';

angular.module('main')
.controller('mainCtrl', function ($scope, $state, projectService, $mdSidenav, $mdDialog, dashboardConfigService, $sce) {
    'ngInject';

    var originatorEv;

    $scope.dashboardConfig = dashboardConfigService.getConfig();
    if ('appTemplates' in $scope.dashboardConfig)
    {
        $scope.appTemplate = $sce.trustAsResourceUrl($scope.dashboardConfig['appTemplates'][0]);
    }
    else
    {
        $scope.appTemplate = null;
    }

    //projectService.init();
    $scope.projects = projectService.list;
    $scope.current_project = projectService.current;
    $scope.isRightSideNavOpen = false;

    $scope.openMenu = function($mdOpenMenu, ev) {
      originatorEv = ev;
      $mdOpenMenu(ev);
    };

    $scope.setAppTemplate = function(templateNum) {
        $scope.appTemplate = $sce.trustAsResourceUrl($scope.dashboardConfig['appTemplates'][templateNum]);
    };

    $scope.setAppRoute = function(route) {
        var w = document.getElementById('preview-frame').contentWindow;
        var obj = {
            action: "setAppRoute",
            route: route
        };
        w.postMessage(JSON.stringify(obj), "*");
    }

    $scope.isSelected = function(project) {
        project.tag === $scope.current_project.tag;
    }

    $scope.selectProject = function(idx) {
        console.log("changed");
        //$scope.$digest();
        projectService.setCurrent($scope.current_project.project).then(function() {
            $state.reload();            
        });
    }

    $scope.toggleLeft = buildToggler('left');
    $scope.isLockedOpen = true;

    function buildToggler(componentId) {
      return function() {
        //$scope.isLockedOpen = !$scope.isLockedOpen;
        $mdSidenav(componentId).toggle();
      }
    }

    $scope.publish = function(){
        console.log("publishing");
        var confirm = $mdDialog.confirm()
            .title('Confirm Publish')
            .textContent('Are you sure you\'d like to publish? All your local changes will go live!')
            .ariaLabel('Secondary click demo')
            .ok('Publish')
            .cancel('Cancel');

        $mdDialog.show(confirm).then(function() {
            var projectBase = 'projectData/'+$scope.current_project.project.tag;
            var ref = firebase.database().ref(projectBase + '/admin/design');
            ref.once('value', function(snap) {
                ref = firebase.database().ref(projectBase + '/publicReadOnly/design');
                ref.set(snap.val());
            });
        });        
    }

    $scope.resetDesign = function() {
        var confirm = $mdDialog.confirm()
            .title('Reset Defaults')
            .textContent('Are you sure you\'d like to delete your custom design?')
            .ariaLabel('Secondary click demo')
            .ok('Reset')
            .cancel('Cancel');

        $mdDialog.show(confirm).then(function() {
            var projectBase = 'projectData/' + $scope.current_project.project.tag;
            ref = firebase.database().ref(projectBase + '/publicReadOnly/design');
            ref.remove();
        });
    }

    $scope.resetAppState = function() {
        var w = document.getElementById('preview-frame').contentWindow;
        var obj = {
            action: "previewAppAPI",
            functionName: "reset"
        };
        w.postMessage(JSON.stringify(obj), "*");
    }

    $scope.signOut = function()
    {
        firebase.auth().signOut().then(function() {
          console.log('Signed Out');
        }, function(error) {
          console.error('Sign Out Error', error);
        });
    }

    window.previewframeOnLoad=function() {
        var w = document.getElementById('preview-frame').contentWindow;
        var obj = {
            action: "previewAppAPI",
            functionName: "bootstrap",
            functionArgs: [projectService.current.project.tag]
        };
        w.postMessage(JSON.stringify(obj), "*");        
        $scope.setAppRoute(['members', 'tabs', 'tabs', 'tab2']);
    }

});
