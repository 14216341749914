'use strict';

angular.module('formlyFieldTypes')
.run(function(formlyConfig) {
    "ngInject";
    
    formlyConfig.setType({
      name: 'bsl-text',
      templateUrl: 'formlyFieldTypes/bsl-text/template.html',
    });

});
