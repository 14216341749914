'use strict';

angular.module('content')
.config(function($stateProvider) {
    'ngInject';

    $stateProvider
    .state({
        parent: 'main',
        name: 'content',
        url: '/content',
        abstract: true,
        views: {
            'content': {
                template: '<ui-view/>'
            }            
        }
    })
    .state('content.list', {
        views: {
            "toolbar@main": {
                templateUrl: 'content/tabs.html',
                controller: 'contentTabCtrl',
            },
            "": {
                controller: 'contentCtrl',
                templateUrl: 'content/content.html'
            }
        }
    })
    .state('content.detail', {
        params: {schemaIndex: null, uri: null, initData: null},
        url: "/detailz",
        views: {
            "": {
                    controller: 'contentDetailCtrl',
                    templateUrl: 'content/contentDetail.html',
            }
        }
    })
    .state('content.email', {
        params: {schemaIndex: null, uri: null, initData: null},
        url: "/email",
        views: {
            "": {
                    controller: 'emailDetailCtrl',
                    templateUrl: 'content/emailDetail.html',
            }
        }
    })
    .state('content.email2', {
        params: {schemaIndex: null, uri: null, initData: null},
        url: "/email2",
        views: {
            "": {
                    controller: 'emailDetailCtrl2',
                    templateUrl: 'content/emailDetail2.html',
            }
        }
    })
    .state('content.groups', {
        params: {schemaIndex: null, uri: null, initData: null},
        url: "/groups",
        views: {
            "": {
                    controller: 'groupsCtrl',
                    templateUrl: 'content/groupsDetail.html',
            }
        }
    })
    .state('content.push', {
        params: {schemaIndex: null, uri: null, initData: null},
        url: "/push",
        views: {
            "": {
                    controller: 'pushCtrl',
                    templateUrl: 'content/pushDetail.html',
            }
        }
    })
    .state('content.push2', {
        params: {schemaIndex: null, uri: null, initData: null},
        url: "/push2",
        views: {
            "": {
                    controller: 'pushDetailCtrl2',
                    templateUrl: 'content/pushDetail2.html',
            }
        }
    })
    .state('content.surveys', {
        params: {schemaIndex: null, uri: null, initData: null},
        url: "/surveys",
        views: {
            "": {
                    controller: 'surveyCtrl',
                    templateUrl: 'content/surveyDetail.html',
            }
        }
    })
    .state('content.vouchers', {
        params: {schemaIndex: null, uri: null, initData: null},
        url: "/vouchers",
        views: {
            "": {
                    controller: 'contentDetailCtrl',
                    templateUrl: 'content/voucherDetail.html',
            }
        }
    })
    .state('content.sms', {
        params: {schemaIndex: null, uri: null, initData: null},
        url: "/sms",
        views: {
            "": {
                    controller: 'smsDetailCtrl',
                    templateUrl: 'content/smsDetail.html',
            }
        }
    })
    .state('content.sms2', {
        params: {schemaIndex: null, uri: null, initData: null},
        url: "/sms2",
        views: {
            "": {
                    controller: 'smsDetailCtrl2',
                    templateUrl: 'content/smsDetail2.html',
            }
        }
    })
    .state('content.qVouchers', {
        params: {schemaIndex: null, uri: null, initData: null},
        url: "/qVouchers",
        views: {
            "": {
                    controller: 'qVoucherCtrl',
                    templateUrl: 'content/qVoucher.html',
                }
        }
    })
    .state('content.menuGroups', {
        params: {schemaIndex: null, uri: null, initData: null},
        url: "/menuGroups",
        views: {
            "": {
                    controller: 'menuGroupCtrl',
                    templateUrl: 'content/menuGroup.html',
                }
        }
    })
    // $stateProvider
    //     .state('main.json.value_detail', {
    //         // url: '/edit',
    //         params: ['project', 'schemaIndex', 'uri'],

    //         // purpose of vars is to carry state from onEnter to onExit
    //         resolve: {vars: function() { return {}; }},

    //         onEnter: ['vars', '$modal', '$stateParams', '$state', function (vars, $modal, $stateParams, $state) {
    //             vars.modal = $modal.open({
    //                 templateUrl: '/components/json-detail/detail.html',
    //                 controller: 'jsonDetailController',
    //                 resolve: {
    //                     uri: function () {
    //                         return $stateParams.uri;
    //                     },
    //                     schemaIndex: function() {
    //                         return $stateParams.schemaIndex;
    //                     },
    //                 }
    //             });
    //             vars.modal.result
    //             .then(function() {}, function () {
    //                      console.log('Modal dismissed at: ' + new Date());
    //                      vars.dismissed = true;
    //                      $state.go('main.json');
    //                  });
    //             }],
    //         onExit: ['vars', function(vars) {
    //             if (!vars.dismissed)
    //                 vars.modal.close();
    //         }]
    //     });

});
