'use strict';

angular.module('formlyFieldTypes')
.run(function(formlyConfig) {
    "ngInject";
    

    formlyConfig.setType({
        name: 'bsl-email-list',
        templateUrl: 'formlyFieldTypes/bsl-email-list/template.html',
        
        
        controller: ['$scope', function($scope) {
            console.log($scope);
            $scope.tmp_email = '';

            $scope.$watch('tmp_email', function (newValue) {
                if (!newValue) {
                    return;
                }

                $scope.to.validator(newValue)
                .then(function(result) {
                    if (result.length > 0) {
                        console.log('validated: ' + newValue);
                        $scope.model[$scope.options.key].push(result[0]);
                        $scope.tmp_email = '';
                    }
                });

            });

        }],
    });

});
