angular.module('md-theme')
.config(function($mdThemingProvider) {
    'ngInject';

// $text-color: $grey;
// $brand-primary: #65BFE4;
// $dark-primary: #25737d;
// $secondary: #85744F;
// $dark-secondary: #3d1d25;
// $brand-danger: #e54444 !default;

// $link-color: $brand-primary;
// $link-hover-color: $dark-primary;


  $mdThemingProvider.definePalette('brandPrimary', {
    '50': '65BFE4',
    '100': '65BFE4',
    '200': '65BFE4',
    '300': '65BFE4',
    '400': '65BFE4',
    '500': '65BFE4',
    '600': '65BFE4',
    '700': '65BFE4',
    '800': '65BFE4',
    '900': '65BFE4',
    'A100': '65BFE4',
    'A200': '65BFE4',
    'A400': '65BFE4',
    'A700': '65BFE4',
    'contrastDefaultColor': 'light',    // whether, by default, text (contrast)
                                        // on this palette should be dark or light
    'contrastDarkColors': ['50', '100', //hues which contrast should be 'dark' by default
     '200', '300', '400', 'A100'],
    'contrastLightColors': undefined    // could also specify this if default was 'dark'
  });

  $mdThemingProvider.definePalette('brandSecondary', {
    '50': '67b7e1',
    '100': '67b7e1',
    '200': '67b7e1',
    '300': '67b7e1',
    '400': '67b7e1',
    '500': '67b7e1',
    '600': '67b7e1',
    '700': '67b7e1',
    '800': '67b7e1',
    '900': '67b7e1',
    'A100': '67b7e1',
    'A200': '67b7e1',
    'A400': '67b7e1',
    'A700': '67b7e1',
    'contrastDefaultColor': 'light',    // whether, by default, text (contrast)
                                        // on this palette should be dark or light
    'contrastDarkColors': ['50', '100', //hues which contrast should be 'dark' by default
     '200', '300', '400', 'A100'],
    'contrastLightColors': undefined    // could also specify this if default was 'dark'
  });

  // $mdThemingProvider.theme('default')
  //   .primaryPalette('brandPrimary')
  //   .accentPalette('brandSecondary');

  $mdThemingProvider.theme('default')
    .primaryPalette('brandSecondary')
    .accentPalette('brandPrimary');

});
