'use strict';

angular.module('formlyFieldTypes')
.run(function(formlyConfig) {
    "ngInject";

    formlyConfig.setType({
      name: 'bsl-textarea',
      templateUrl: 'formlyFieldTypes/bsl-textarea/template.html',
    });
    
});