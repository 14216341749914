'use strict';

function get_pricing_from_event(anEvent)
{
    var pricing_level_text = {}
    if (anEvent.Pricing.PriceLevelAxis.PriceLevel instanceof Array)
    {
        var guid, name;
        for (priceLevelIdx in anEvent.Pricing.PriceLevelAxis.PriceLevel)
        {
            guid = anEvent.Pricing.PriceLevelAxis.PriceLevel[priceLevelIdx]["@attributes"].Guid;
            name = anEvent.Pricing.PriceLevelAxis.PriceLevel[priceLevelIdx]["@attributes"].Name;
            pricing_level_text[guid] = name;
        }
    }
    else
    {
        var guid, name;
        if ('PriceLevel' in anEvent.Pricing.PriceLevelAxis) {
            guid = anEvent.Pricing.PriceLevelAxis.PriceLevel["@attributes"].Guid;
            name = anEvent.Pricing.PriceLevelAxis.PriceLevel["@attributes"].Name;
            pricing_level_text[guid] = name;
        }
    }

    var pricing_type_text = {}
    if (anEvent.Pricing.PriceTypeAxis.PriceType instanceof Array)
    {
        var guid, type;
        for (priceTypeIdx in anEvent.Pricing.PriceTypeAxis.PriceType)
        {
            guid = anEvent.Pricing.PriceTypeAxis.PriceType[priceTypeIdx]["@attributes"].Guid;
            type = anEvent.Pricing.PriceTypeAxis.PriceType[priceTypeIdx]["@attributes"].Name;
            pricing_type_text[guid] = type;
        }
    }
    else
    {
        var guid, type;
        guid = anEvent.Pricing.PriceTypeAxis.PriceType["@attributes"].Guid;
        type = anEvent.Pricing.PriceTypeAxis.PriceType["@attributes"].Name;
        pricing_type_text[guid] = type;
    }

    var pricing_matrix = {}
    if (anEvent.Pricing.PricingMatrix.Entry instanceof Array)
    {
        var level, type, price;
        for (priceEntryIdx in anEvent.Pricing.PricingMatrix.Entry)
        {
            level = anEvent.Pricing.PricingMatrix.Entry[priceEntryIdx]["@attributes"].PriceLevelGuid;
            type = anEvent.Pricing.PricingMatrix.Entry[priceEntryIdx]["@attributes"].PriceTypeGuid;
            price = anEvent.Pricing.PricingMatrix.Entry[priceEntryIdx]["@attributes"].TotalPrice;
            level = pricing_level_text[level];
            type = pricing_type_text[type];
            if (pricing_matrix[level] === undefined)
            {
                pricing_matrix[level] = {}
            }
            pricing_matrix[level][type] = price;
        }
    }
    else
    {
        var level, type, price;
        if ('Entry' in anEvent.Pricing.PricingMatrix) {
        level = anEvent.Pricing.PricingMatrix.Entry["@attributes"].PriceLevelGuid;
        type = anEvent.Pricing.PricingMatrix.Entry["@attributes"].PriceTypeGuid;
        price = anEvent.Pricing.PricingMatrix.Entry["@attributes"].TotalPrice;
        level = pricing_level_text[level];
        type = pricing_type_text[type];
        pricing_matrix[level] = {}
        pricing_matrix[level][type] = price;
        }
    }
    return pricing_matrix;
}

angular.module('facebook')
.factory('facebookService', function (tsSettings, $http, $window, $q, projectService, dashboardConfigService) {
    "ngInject";

    var events = [];
    var fb_events = [];
    var id = 0;
    var accessToken = null;
    var dashboardConfig = dashboardConfigService.getConfig();

    var initFacebookSDK = function () {
        var deferred = $q.defer();

        (function(d, s, id){
             var js, fjs = d.getElementsByTagName(s)[0];
             if (d.getElementById(id)) {return;}
             js = d.createElement(s); js.id = id;
             js.src = "//connect.facebook.net/en_US/sdk.js";
             fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));

        // if we're already connected no need to
        // initialise fb sdk
        if ($window.FB)
        {
                // At this point we're guaranteed that the 
                // FB SDK is initialised.
                $window.FB.getLoginStatus(function(response) {
                  if (response.status === 'connected') {
                    // the user is logged in and has authenticated your
                    // app, and response.authResponse supplies
                    // the user's ID, a valid access token, a signed
                    // request, and the time the access token 
                    // and signed request each expire
                    var uid = response.authResponse.userID;
                    accessToken = response.authResponse.accessToken;
                    deferred.resolve();

                  } else if (response.status === 'not_authorized') {
                        accessToken = null;
                        deferred.reject();
                  } else {
                    // the user isn't logged in to Facebook.
                    accessToken = null;
                    deferred.reject();
                  }
                 });
        }
        else
        {
            $window.fbAsyncInit = function() {
                
                FB.init({
                      appId      : dashboardConfig.facebookAppId || '679866138753998',
                      xfbml      : true,
                      version    : 'v2.11',
                      status     : true
                });

                // At this point we're guaranteed that the 
                // FB SDK is initialised.
                $window.FB.getLoginStatus(function(response) {
                  if (response.status === 'connected') {
                    // the user is logged in and has authenticated your
                    // app, and response.authResponse supplies
                    // the user's ID, a valid access token, a signed
                    // request, and the time the access token 
                    // and signed request each expire
                    var uid = response.authResponse.userID;
                    accessToken = response.authResponse.accessToken;
                    deferred.resolve();

                  } else if (response.status === 'not_authorized') {
                        /*$window.FB.login(function(response) {
                            if (response.authResponse) {

                                accessToken = response.authResponse.accessToken;

                            } else {
                                console.log('User cancelled login or did not fully authorize.');
                            }
                        });*/
                        accessToken = null;
                        deferred.reject();
                  } else {
                    // the user isn't logged in to Facebook.
                    accessToken = null;
                    deferred.reject();
                    //deferred.resolve();
                  }
                 });
            };
        }
        return deferred.promise;
    }

    function addBSLEventFromFbEvent (venue_name, fbEvent, event_pictures) {
            var anEvent = {};
            var event_time = {};
            var event_times = [];
            anEvent.short_name = fbEvent.name;
            anEvent.description = fbEvent.description;
            anEvent.venue = venue_name;
            anEvent.ticket_info = 'http://fake.com';
            anEvent.external_url = 'http://fake.com';
            anEvent.event_picture = event_pictures[fbEvent.id] || "";
            if (fbEvent.cover !== undefined) {
                anEvent.cover_url = fbEvent.cover.source;
            }
            var fbDate = new Date(fbEvent.start_time)
            //var bslDate = new Date(fbDate.getTime() - (fbDate.getTimezoneOffset() * 60 * 1000));
            event_time.time = fbDate;
            event_times.push(event_time);
            anEvent.event_times = event_times;
            events.push(anEvent);
            fb_events.push(anEvent);
    }

    var initFacebookEventData = function (page_id) {

        var deferred = $q.defer();

        if (!page_id)
            deferred.reject();

        // clear old event data
        fb_events.length = 0;
        fb_events = [];
        var event_pictures = {};

        // before we can do anything with fb graph, we need to check if this user has authenticated
        // with facebook. to do that we try grab the long life token from the social account for this
        // user and perform graph operations once we have it 

        if (accessToken === undefined || accessToken === "")
            deferred.reject();

        var venue_name;
        $window.FB.api(
        "/" + page_id + "/",
        'get',
        {access_token: accessToken},
            function (response) {
                venue_name = response.name 
        // fb graph optimisation ideas from this dude
        // https://www.sammyk.me/optimizing-request-queries-to-the-facebook-graph-api
        // what im doing here is reading the last 10 posts from the page's feed,
        // checking if they are an event, and if they are storing their id's
        // once i have all the id's i do another call to graph to get the details
        // of all the events
        $window.FB.api(
        "/" + page_id + "/?fields=feed.limit(50){type,object_id,picture}",
        'get',
        {access_token: accessToken},
        function (response) {
          if (response && !response.error) {
            /* handle the result */
            console.log(response);
            if (response.feed.data instanceof Array)
            {
                var event_ids = "";
                for (var idx=0; idx < response.feed.data.length; idx++)
                {
                    if (response.feed.data[idx].type === 'event')
                    {
                        if (event_ids !== "")
                            event_ids = event_ids + ",";
                        event_ids = event_ids + response.feed.data[idx].object_id;
                        event_pictures[response.feed.data[idx].object_id] = response.feed.data[idx].picture;
                    }
                }
                $window.FB.api("/?ids="+event_ids+"&fields=name,description,start_time,id,cover", 
                    'get',
                    {access_token: accessToken},
                    function(response) {
                    if (response && !response.error) {
                        console.log(response);

                        var uri = "projectData/" + projectService.current.project.tag + "/publicReadOnly/facebookEvents/";
                        var ref = new firebase.database().ref(uri);

                        ref.once('value', function(snapshot) {
                            var existingEvents = snapshot.val() || [];
                            var fb_eventsByKey = {};
                            for (var j in response)
                            {
                                response[j].added = (response[j].id in existingEvents);
                                fb_events.push(response[j]);
                                fb_eventsByKey[response[j].id] = response[j];
                            }            
                            // now go through existing events and add if they were deleted from fb
                            for (var j in existingEvents)   
                            {
                                var existingEvent = JSON.parse(existingEvents[j]);
                                if (!(existingEvent.id in fb_eventsByKey)) {
                                    existingEvent.removedFromFacebook = true;
                                    existingEvent.added = true;
                                    fb_events.push(existingEvent);
                                }
                            }     
                            deferred.resolve();
                        });
                    }
                    else
                        deferred.reject(response.error);
                });
            }
          }
          else{
                deferred.reject(response.error);
                accessToken = null;
            }

            });
        });

        return deferred.promise;
    };

    var initData = function(project) {


        // clear old event data
        events.length = 0;

        // Add 'sro' proxy to bsl via admin/ interface
        // http://purchase.yourcentre.com.au/feed/events/?groupby=show&withpricing=true
        xml_url = tsSettings.sroProxyUrl(project);

        $http.get(xml_url, {url: xml_url, headers: {
                'Content-Type': 'application/xml; charset=utf-8'
            }}).then(function(response) 
        {
            var xml = response.data
            if (window.DOMParser) {
                xml = (new DOMParser).parseFromString(xml,'text/xml');
            } else if (window.ActiveXObject) {
                xml = [new ActiveXObject('Microsoft.XMLDOM'), xml];
                xml[0].async = false;
                xml[0].loadXML(xml[1]);
                xml = xml[0];
            }

            json = xmlToJson(xml);
            console.log(json);
            
            for (show_idx in json.feed.Show)
            {
                var event_times = [];
                var anEvent = {};
                var show = json.feed.Show[show_idx];
                anEvent.short_name = show.Name['#text'];
                anEvent.external_url = show.DirectLink['#text'];
                var pricing_matrix = {};

                if (show.events.Event instanceof Array)
                {
                    for (event_time_idx in show.events.Event)
                    {
                        var event_time = {};
                        value = show.events.Event
                        event_time.time = show.events.Event[event_time_idx].ActualEventDate['#text'];
                        event_times.push(event_time);

                        // get pricing details
                        pricing_matrix = get_pricing_from_event(show.events.Event[event_time_idx])
                        var venue = show.events.Event[event_time_idx].VenueName['#text'];
                    }
                }
                else
                {
                    var event_time = {};
                    event_time.time = show.events.Event.ActualEventDate['#text'];
                    event_times.push(event_time);
                    pricing_matrix = get_pricing_from_event(show.events.Event)
                    var venue = show.events.Event.VenueName['#text'];
                }

                anEvent.event_times = event_times;
                anEvent.pricing = JSON.stringify(pricing_matrix.STANDARD);
                anEvent.venue = venue;
                anEvent.id = id;
                id++;
                events.push(anEvent);
            }
          });
    };

    var link = function () {
        var deferred = $q.defer();

        $window.FB.login(function(response) {
            if (response.authResponse) {

                accessToken = response.authResponse.accessToken;
                deferred.resolve();

            } else {
                console.log('User cancelled login or did not fully authorize.');
                deferred.reject();
            }
        });
        return deferred.promise;
    }


return {
    initData: initData,

    initFacebookEventData: initFacebookEventData,

    initFacebookSDK: initFacebookSDK,

    getAccessToken: function() 
    { 
        return accessToken; 
    },

    all: function () {
        return events;
    },

    fb_events: function() {
        return fb_events;
    },

    get: function (eventId) {
        return events[eventId];
    },
    
    get_from_name: function(name) {
        for (idx in fb_events)
        {
            var fbName = fb_events[idx].name.toLowerCase().replace(/[^\w ]+/g,'').replace(/ +/g,'_');
            if (fbName === name)
                return fb_events[idx];
        }
        return null;
    },

    link: link
};

})
