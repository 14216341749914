'use strict';

angular.module('formlyFieldTypes')
.run(function(formlyConfig) {
    "ngInject";
    
    formlyConfig.setType({
      name: 'bsl-icon',
      templateUrl: 'formlyFieldTypes/bsl-icon/template.html',
    });

});