'use strict';

// did this because copyFields() below was finding
// angular undefined...
var _angular = angular;

angular.module('formlyFieldTypes')
.config(function(formlyConfigProvider) {
    "ngInject";
    
    var unique = 1;

    formlyConfigProvider.setType({
        name: 'bsl-repeat',
        templateUrl: 'formlyFieldTypes/bsl-repeat/template.html',

        controller: function($scope) {
            "ngInject";

            $scope.formOptions = {formState: $scope.formState};
            $scope.addNew = addNew;

            $scope.copyFields = copyFields;

            function copyFields(fields) {
                fields = _angular.copy(fields);
                addRandomIds(fields);
                return fields;
            }

            function addNew() {
                $scope.model.data[$scope.options.key] = $scope.model.data[$scope.options.key] || [];
                var repeatsection = $scope.model.data[$scope.options.key];
                var lastSection = repeatsection[repeatsection.length - 1];
                var newsection = {};

                if (lastSection) {
                    newsection = _angular.copy(lastSection);
                }
                repeatsection.push(newsection);
            }

            function addRandomIds(fields) {
                unique++;
                _angular.forEach(fields, function(field, index) {
                    if (field.fieldGroup) {
                        addRandomIds(field.fieldGroup);
                        return; // fieldGroups don't need an ID
                    }
                    
                    if (field.templateOptions && field.templateOptions.fields) {
                        addRandomIds(field.templateOptions.fields);
                    }
                    
                    field.id = field.id || (field.key + '_' + index + '_' + unique + getRandomInt(0, 9999));
                });
            }

            function getRandomInt(min, max) {
                return Math.floor(Math.random() * (max - min)) + min;
            }
        },
    });
});
