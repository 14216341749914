'use strict';

angular.module('auth')
.controller('signInCtrl', function ($scope, $state, projectService, dashboardConfigService) {
    'ngInject';

    $scope.dashboardConfig = dashboardConfigService.getConfig();

    $scope.busy = false;
    $scope.signIn = function(email,password) 
    {

        $scope.busy = true;
        firebase.auth().signInWithEmailAndPassword(email, password).then(function(user) {
            $scope.busy = false;
        },
        function(error) {
            $scope.busy = false;
            $scope.$digest();
        });
    };


});
