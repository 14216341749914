// the purpose of this directive is to make elements in a
// directives partial accessible to its controller
// see: http://stackoverflow.com/questions/15881453/angularjs-accessing-dom-elements-inside-directive-template
angular.module('imageAdmin').directive("bslScopeElement", function () {
    "ngInject";

    return {
        restrict: "A",

        compile: function compile(tElement, tAttrs, transclude) {
            return {
                pre: function preLink(scope, iElement, iAttrs, controller) {
                  // wrap the element as a jQuery object. This is required for
                  // panzoom to work, but is probably good for other stuff also.
                  scope[iAttrs.bslScopeElement] = jQuery(iElement);
                }
            };
        }
    };
});


// implements the bsl-drop-zone directive
// There are other implementations of this, but this one gives
// us the drop callback, rather than manipulating the outer scope
angular.module('imageAdmin').directive('bslDropZone', [function() {
    "ngInject";

    return {
        // these are attributes assigned in directive
        scope: {
            // drop is a callback function in outer scope to
            // handl drop events
            drop: "=",
        },

        restrict: 'A',
        link: function(scope,element,attrs){
            var el = element[0];
            el.addEventListener(
                'dragover',
                function(e) {
                    e.dataTransfer.dropEffect = 'move';
                    // allows us to drop
                    if (e.preventDefault) e.preventDefault();

                    // let the css make it look like an active drop zoon
                    this.classList.add('over');
                    return false;
                },
                false
            );
            el.addEventListener(
                'drop',
                function(e) {
                    // Stops some browsers from redirecting.
                    if (e.stopPropagation) e.stopPropagation();

                    // This is critical. Stop the browser doing its
                    // default thing ie loading the image
                    e.preventDefault();

                    // let css remove active drop zoon style
                    this.classList.remove('over');

                    var file = e.dataTransfer.files[0];

                    // call the drop passed drop function
                    scope.$apply(function() {
                        scope.drop(file);
                    });
                    
                    return true;
                },
                false
            );
        },
    };
}]);


angular.module('imageAdmin').directive("fileLoaded", function () {
    "ngInject";

    return {
        scope: {
            fileLoaded: "="
        },
        link: function (scope, element, attributes) {
            element.bind("change", function (changeEvent) {
                var reader = new FileReader();
                reader.onload = function (loadEvent) {
                    scope.$apply(function () {
                        scope.fileLoaded(loadEvent.target.result);
                    });
                }
                reader.readAsDataURL(changeEvent.target.files[0]);
                // this was the fix to allow the same file to be selected
                // again
                // http://stackoverflow.com/questions/12030686/html-input-file-selection-event-not-firing-upon-selecting-the-same-file
                changeEvent.target.value = null;
            });
        }
    }
});


// Implements bsl-image-admin which is where the magic happens
angular.module('imageAdmin').directive('bslImageAdmin', function() {
    "ngInject";

    return {
        restrict: 'E',
        templateUrl: 'imageAdmin/imageAdmin.html',
        require: '?ngModel',
        scope: {
            // = binds to element in parent scope property
            // assigned via the schema= attribute in the partial
            schema: "=",
            // image normalization factor - to scale relative to
            // largest
            norm: "=",
            // callback to update image
            update: "="
        },

        // use a link to bind incoming scope
        // use a controller if we wanted to implement a public API
        link: function link(scope, element, attrs, ngModel) {
            var canvas = scope.canvas;
            if (!canvas || !canvas.length) {
                console.error("Error; Invalid canvas attribute");
                return;
            }

            scope.image = '';
            if (ngModel) {
                ngModel.$render = function() {
                    scope.image = ngModel.$viewValue;
                }
            }

            scope.$watch('schema', function(result) {
                    // The image URL by default is just /media/...
                    // We need to prepend the bsl server URL now that the
                    // static files are served separately to bsl.
//                    var serverUrl = globalConfig.apiUrl;
                    var serverUrl = '/';
                    if(serverUrl.substr(-1) === '/') {
                        serverUrl = serverUrl.substr(0, serverUrl.length - 1);
                    }

                    // append a date query to force a reload since
                    // the URL doesn't actually change
                    if (result.image) {
                        scope.image = serverUrl + result.image.image + '?' + new Date().getTime();
                    }
                },
            // true is for deep watch i.e. schema.image
            true);

            scope.editImg = false;

            if (attrs.initfromurl !== undefined && attrs.initfromurl != "")
            {
                scope.initImageURL = attrs.initfromurl;
                var xhr = new XMLHttpRequest();
                xhr.open('GET', scope.initImageURL, true);
                xhr.responseType = 'blob';
                xhr.onload = function(e) {
                  if (this.status == 200) {
                    var myBlob = this.response;
                    // myBlob is now the blob that the object URL pointed to.
                    scope.drop(myBlob);
                  }
                };
                xhr.send();
            }

            // initialise the pan and zoom capability on the img
            // see panzoom doco:
            // https://github.com/timmywil/jquery.panzoom/blob/master/README.md
            var panzoom = canvas.panzoom({
                // startTransform: 'scale(1.0)',
                minScale: 1.0,

                contain: false,

                // $zoomIn: element.find(".zoom-in"),
                // $zoomOut: element.find(".zoom-out"),
                // $zoomRange: $section.find(".zoom-range"),
                $reset: element.find(".reset")
            });

            scope.zoom = function(zoomOut) {
                // zoomOut = zoomOut || true;
                var zoom = zoomOut ? 1.1 : 1 / 1.1;
                var transform = panzoom.panzoom("getMatrix");
                transform[0] = +transform[0] * zoom;
                transform[3] = +transform[3] * zoom;
                // make sure we don't zoom out too far
                // if (transform[0] < 1 && transform[0] < transform[3]) {
                //     transform[3] /= transform[0];
                //     transform[0] = 1;
                // } else if (transform[3] < 1) {
                //     transform[0] /= transform[3];
                //     transform[3] = 1;
                // }
                panzoom.panzoom("setMatrix", transform);
            }

            // This bit handles zooming with the mouse or pinch
            panzoom.parent().on('mousewheel.focal', function( e ) {
                e.preventDefault();
                var delta = e.delta || e.originalEvent.wheelDelta;
                var zoomOut = delta ? delta < 0 : e.originalEvent.deltaY > 0;
                // panzoom.panzoom('zoom', zoomOut, {
                //     increment: 0.2,
                //     focal: e,
                // });
                scope.zoom(zoomOut);
            });

            // the cancel button click handler
            // which is only visible in editImg mode
            scope.cancelEdit = function() {
                scope.editImg = false;
            }

            // the OK button click handler
            // which is only visible in editImg mode
            scope.commitEdit = function() {
                var matrix = panzoom.panzoom("getMatrix");

                // TODO: extract the bit to be scaled
                var imgWidth = scope.canvas[0].width;
                var imgHeight = scope.canvas[0].height;

                var width = Math.round(imgWidth / +matrix[0]);
                var height = Math.round(imgHeight / +matrix[3]);

                // offset of viewport in img coordinates
                var xorig = (imgWidth - width) / 2 - matrix[4] * imgWidth / scope.canvas[0].offsetWidth / +matrix[0]; 
                var yorig = (imgHeight - height) / 2 - matrix[5] * imgHeight / scope.canvas[0].offsetHeight / +matrix[3]; 
                xorig = Math.round(xorig);
                yorig = Math.round(yorig);

                var ctx = scope.canvas[0].getContext("2d");

                // console.log([xorig, yorig, width, height]);

                // actually do the resize
                // TODO: apparently zoom works better if you iteratively
                // scale by 2 & then correct for whatever remains...
                var dstCanvas = document.createElement("canvas");
                dstCanvas.width = scope.schema.width;
                dstCanvas.height = scope.schema.height;
                var dstContext = dstCanvas.getContext("2d");
                dstContext.drawImage(scope.canvas[0],
                    xorig, yorig, width, height,
                    0, 0, scope.schema.width, scope.schema.height);

                var imgData = dstCanvas.toDataURL("image/png");

                if (scope.update) {
                    scope.update(scope.schema, imgData)
                }
                if (ngModel) {
                    ngModel.$setViewValue(imgData);
                    scope.image = imgData;

                }
                scope.editImg = false;                
            }

            scope.onChange = function(arg) {
                console.log(arg);
            }

            scope.beginEdit = function(imgSrc) {
                var img;
                if (imgSrc !== undefined) {

                    img = new Image();
                    img.onload = imageLoaded;
                    img.src = imgSrc;
                } else {
                    img = scope.bslImage;
                    imageLoaded();
                }

                function imageLoaded() {

                    // everything from here needs to happen inside
                    // angular world. Use $apply to make that happen
                    scope.$apply(function () {

                        var hscale = scope.schema.width / img.width;
                        var vscale = scope.schema.height / img.height;

                        var srcCanvas = scope.canvas[0];
                        srcCanvas.width = img.width;
                        srcCanvas.height = img.height;
                        var srcContext = srcCanvas.getContext("2d");
                        srcContext.drawImage(img, 0, 0, img.width, img.height);

                        // set the initial transform so that the image fills the schema
                        // but maintains aspect ratio
                            // the transform matrix is described here:
                            // http://www.w3schools.com/tags/canvas_transform.asp
                            // a   Scales the drawing horizontally
                            // b   Skew the the drawing horizontally
                            // c   Skew the the drawing vertically
                            // d   Scales the drawing vertically
                            // e   Moves the the drawing horizontally
                            // f   Moves the the drawing vertically
                        var transform = [0, 0, 0, 0, 0, 0];
                        transform[0] = Math.max(hscale, vscale)/hscale;
                        transform[3] = Math.max(hscale, vscale)/vscale;

                        // panzoom.panzoom("setMatrix", transform);
                        panzoom.panzoom("option", {
                            startTransform: "matrix(" + transform + ")",
                        });
                        panzoom.panzoom("reset");

                        // finally, display the editor
                        scope.editImg = true;
                    });
                }
            }

            // the drop handler is passed to the bsl-drop-zone directive
            // and is called on a drop event
            // When a new file is dropped, just update the img - don't save
            // to bsl
            scope.drop = function(file) {
                var fr = new FileReader();
                fr.onload = function () {
                        // pass the loaded image to the editor
                        scope.beginEdit(fr.result);
                    };
                fr.readAsDataURL(file);
            }
        }
    };
});
