'use strict';

angular.module('SRO')
.controller('SROCtrl', function ($scope, SROService, projectService, contentStorageService) {
    'ngInject';

    SROService.initFacebookSDK().then(function() {
        SROService.initFacebookEventData(projectService.currentValue.SRO_url).then(function() {
            $scope.sro_events = SROService.fb_events();
        });
    });
});
