'use strict';

angular.module('auth')
.config(function($stateProvider) {
    'ngInject';

    $stateProvider
    .state({
        name: 'signIn',
        url: '/signIn',
        controller: 'signInCtrl',
        templateUrl: 'auth/signIn.html',
    })
 
});
